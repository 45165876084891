import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import SlideLayout from '../../../layout/slide';
import DefaultPrizeImage from '../../../../assets/images/default-prize.jpg';
import PrizeInfo from '../../../../components/milestones/prizeInfo';
import styles from './styles.module.scss';
import asset from '../../../../helpers/asset';

export interface PrizeAwardedSlide extends Slide {
  key: 'prizeAwarded';
  title: string;
  subtitle?: string;
  content?: string;
  imageUrl?: string;
}

export function PrizeAwarded(props: SlideProps<PrizeAwardedSlide>) {
  const slide = useSlide(props, {
    enterDuration: 2000,
    updateDuration: 500,
    leaveDuration: 2000,
  });

  const { stage } = props;

  return (
    <SlideLayout>
      <div className={styles.prizeAwardedSlide}>
        <PrizeInfo
          mainTitle={slide.title}
          subTitle={slide.subtitle}
          additionalText={slide.content}
          imageUrl={slide.imageUrl || asset(DefaultPrizeImage)}
          mounted={stage === 'entering' || stage === 'mounted'}
        />
      </div>
    </SlideLayout>
  );
}

export default PrizeAwarded;

import { useState, useEffect } from 'react';
import { Globals as SpringGlobals } from 'react-spring';
import { Slide, SlideProps } from '../models';

interface SlideDuration {
  enterDuration: number;
  // how long does it take the widgets to update with new props and finish all transitions so that they're fully updated and resting
  updateDuration: number;
  // how long does it take the slide to finish all leaving transitions so that it's fully invisible and resting
  leaveDuration: number;
}

export function useSlide<T extends Slide>(props: SlideProps<T>, duration: SlideDuration) {
  const [slide, setSlide] = useState(
    !SpringGlobals.skipAnimation && props.previous ? props.previous : props.current,
  );

  const { current, previous, stage, onEnter, onLeave } = props;
  const { enterDuration, updateDuration, leaveDuration } = duration;

  useEffect(() => {
    if (SpringGlobals.skipAnimation || stage !== 'entering') {
      return;
    }

    // if we have a previous slide, but are rendering the current one,
    // then it's the 'update' phase,
    // otherwise it's the 'entering phase'
    const duration = previous && slide === current ? updateDuration : enterDuration;

    const timeout = setTimeout(() => {
      if (slide === current) {
        onEnter();
      } else {
        setSlide(current);
      }
    }, duration);

    return () => timeout && clearTimeout(timeout);
  }, [current, previous, slide, stage, onEnter, enterDuration, updateDuration]);

  useEffect(() => {
    if (SpringGlobals.skipAnimation || stage !== 'leaving') {
      return;
    }

    const timeout = setTimeout(() => onLeave(), leaveDuration);

    return () => timeout && clearTimeout(timeout);
  }, [stage, onLeave, leaveDuration]);

  useEffect(() => {
    if (!SpringGlobals.skipAnimation) {
      return;
    }

    if (stage === 'entering') {
      setTimeout(() => onEnter(), 1000);
    } else if (stage === 'leaving') {
      onLeave();
    }
  }, [stage, onEnter, onLeave]);

  // when we get updates after the slide has fully mounted
  // we can just return the current slide straightaway and render based off it
  if (stage === 'mounted') {
    return current;
  }

  return slide;
}

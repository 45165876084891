import 'react-app-polyfill/stable';
import './assets/scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Globals } from 'react-spring';
import App from './app';
import Error from './app/error';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  CaptureConsole as CaptureConsoleIntegration,
  Offline as OfflineIntegration,
} from '@sentry/integrations';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing(), new CaptureConsoleIntegration(), new OfflineIntegration()],
  tracesSampleRate: 0,
});

const params = new URLSearchParams(window.location.search);

Globals.assign({
  skipAnimation: params.has('skipAnimation') || false,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Error />}>
      <App groupId={params.get('group') || ''} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

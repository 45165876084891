import { animated, easings, useTransition } from 'react-spring';
import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import { ShoutOut as ShoutOutDTO } from '../../../../models/showcase';
import SlideLayout from '../../../layout/slide';
import ShoutOutMain from '../../../../components/shoutOut/shoutOutMain';
import ShoutOutBox from '../../../../components/shoutOut/shoutOutBox';
import styles from './styles.module.scss';

export interface ShoutOutSlide extends Slide {
  key: 'shoutOut';
  name: string;
  shoutOut: ShoutOutDTO;
}

export function ShoutOut(props: SlideProps<ShoutOutSlide>) {
  const slide = useSlide(props, {
    enterDuration: 3000,
    updateDuration: 500,
    leaveDuration: 2000,
  });

  const { stage } = props;

  const transition = useTransition(stage === 'entering' || stage === 'mounted', {
    from: {
      opacity: 0,
      transform: 'translateX(50%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(-50%)',
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuad,
    },
  });

  return (
    <SlideLayout>
      <div className={styles.shoutOutMain}>
        <ShoutOutMain
          type={slide.shoutOut.type}
          name={slide.name}
          mounted={stage === 'entering' || stage === 'mounted'}
        />
      </div>
      {transition(
        (springProps, showing) =>
          showing && (
            <animated.div className={styles.shoutOutBox} style={springProps}>
              <ShoutOutBox
                {...slide.shoutOut}
                mounted={stage === 'entering' || stage === 'mounted'}
              />
            </animated.div>
          ),
      )}
    </SlideLayout>
  );
}

export default ShoutOut;

import { useCallback, useState } from 'react';
import {
  useSpring,
  useTransition,
  animated,
  easings,
  useChain,
  useSpringRef,
  Globals,
} from 'react-spring';
import { ShoutOutType } from '../../../models/showcase';
import useFitText from 'use-fit-text';
import styles from './styles.module.scss';

interface Props {
  type: ShoutOutType;
  name: string;
  mounted: boolean;
}

export function ShoutOutMain({ type, name, mounted }: Props) {
  const [showResizedFont, setShowResizedFont] = useState(false);

  // resize fonts and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowResizedFont(true);
  }, []);

  // name text
  const { fontSize, ref } = useFitText({ onStart, onFinish });
  const nameSpring = useSpring({
    immediate: true,
    to: {
      opacity: showResizedFont ? 1 : 0,
      display: showResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);

  // gateway animation
  const gatewayOpacity = useSpring({
    to: {
      opacity: mounted ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 2000,
    },
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setHasMountedAnimatedIn(!hasMountedAnimatedIn);
    },
  });

  const transitionConfig = {
    from: {
      opacity: 0,
      transform: 'translateX(80%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(-100%)',
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuad,
    },
  };
  const titlePartRef = useSpringRef();
  const titlePartTransitions = useTransition(mounted, {
    ...transitionConfig,
    ref: titlePartRef,
  });

  const namePartRef = useSpringRef();
  const namePartTransitions = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(0%)',
    },
    to: {
      opacity: mounted ? 1 : 0,
      transform: mounted
        ? 'translateX(0%)'
        : `translateX(${hasMountedAnimatedIn ? '-100%' : '80%'})`,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuad,
    },
    ref: namePartRef,
  });

  const reasonPartRef = useSpringRef();
  const reasonPartTransitions = useTransition(mounted, {
    ...transitionConfig,
    ref: reasonPartRef,
  });

  useChain([titlePartRef, namePartRef, reasonPartRef], [0, 0.2, 0.4]);

  return (
    <animated.div style={gatewayOpacity} className={styles.shoutOutMain}>
      {titlePartTransitions(
        (props, item) =>
          item && (
            <animated.div className={styles.trailsText} style={props}>
              <h3 className={styles.title}>Shout out to</h3>
            </animated.div>
          ),
      )}
      <animated.div className={styles.trailsText} style={namePartTransitions}>
        <div className={styles.nameHolder}>
          <animated.div ref={ref} className={styles.name} style={{ fontSize, ...nameSpring }}>
            {name}
          </animated.div>
        </div>
      </animated.div>
      {reasonPartTransitions(
        (props, item) =>
          item && (
            <animated.div className={styles.trailsText} style={props}>
              <div className={styles.reason}>
                for {type === 'mostNumber' ? 'achieving' : 'hitting'}&hellip;
              </div>
            </animated.div>
          ),
      )}
    </animated.div>
  );
}

export default ShoutOutMain;

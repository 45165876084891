import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import SlideLayout from '../../../layout/slide';
import styles from './styles.module.scss';
import { Props as Contributor } from '../../../../components/contributors/contributorBox';
import ContributorSlider from '../../../../components/contributors/contributorSlider';

export interface RecentContributorsSlide extends Slide {
  key: 'recentContributors';
  contributors: Contributor[];
}

export function RecentContributors(props: SlideProps<RecentContributorsSlide>) {
  const slide = useSlide(props, {
    enterDuration: 2200,
    updateDuration: 500,
    leaveDuration: 2200,
  });

  const { stage } = props;

  return (
    <SlideLayout>
      <div className={styles.recentContributorsSlide}>
        <ContributorSlider
          contributors={slide.contributors}
          mounted={stage === 'entering' || stage === 'mounted'}
        />
      </div>
    </SlideLayout>
  );
}

export default RecentContributors;

import { Client } from '../../api';
import { Group, TabName, Screen } from '../../models';
import { ScreenSource } from '.';
import { ScreenFamilySource } from './screenFamilies/screenFamilySource';
import {
  ChallengeScreenFamilySource,
  ShowcaseScreenFamilySource,
  WhiteboardScreenFamilySource,
} from './screenFamilies';

export default class RemoteScreenSource implements ScreenSource {
  private sources: ScreenFamilySource[];
  private index: number = 0;

  constructor(sources: ScreenFamilySource[]) {
    this.sources = sources;
  }

  async seed() {
    await Promise.all(this.sources.map((source) => source.refresh()));
  }

  rewind() {
    this.index = 0;
    this.sources.forEach((source) => source.screenFamily.rewind());
  }

  next(): Screen {
    let screen: Screen | undefined;

    do {
      if (this.nextSource.canRefresh) {
        this.nextSource.refresh();
      }

      const screenFamily = this.source.screenFamily;

      screen = screenFamily.next();

      if (screenFamily.shownAll) {
        this.advanceSource();
      }
    } while (!screen);

    return screen;
  }

  visibleTabs(): Set<TabName> {
    return this.sources.reduce((tabs, source) => {
      source.screenFamily.visibleTabs().forEach((tab) => tabs.add(tab));
      return tabs;
    }, new Set<TabName>());
  }

  private advanceSource() {
    this.index = (this.index + 1) % this.sources.length;
  }

  private get source(): ScreenFamilySource {
    return this.sources[this.index];
  }

  private get nextSource(): ScreenFamilySource {
    return this.sources[(this.index + 1) % this.sources.length];
  }
}

export function createScreenSource(client: Client, group: Group): Promise<RemoteScreenSource> {
  return Promise.resolve(
    new RemoteScreenSource([
      new ChallengeScreenFamilySource(client, group),
      new ShowcaseScreenFamilySource(client, group),
      new WhiteboardScreenFamilySource(client, group),
    ]),
  );
}

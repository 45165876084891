const generateFonts = (family: string) => {
  return [100, 300, 400, 500, 700, 800, 900].map((weight) => {
    return `normal ${weight} 16px ${family}`;
  });
};

export default function loadFont(family: string) {
  const loaders = generateFonts(family)
    .filter((font) => !document.fonts.check(font))
    .map((font) => document.fonts.load(font));

  return Promise.all(loaders);
}

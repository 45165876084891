import { animated } from 'react-spring';
import Challenge from './challenge';
import Showcase from './showcase';
import Whiteboard from './whiteboard';

const tabs = {
  challenge: animated(Challenge),
  showcase: animated(Showcase),
  leaderboards: animated(Whiteboard),
};

export default tabs;

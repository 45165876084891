import React from 'react';
import styles from './styles.module.scss';
import BrawnLogo from '../../../assets/images/svgs/brawn_logo.svg';
import Image from '../../../components/image';
import asset from '../../../helpers/asset';

interface Props {
  children: React.ReactNode;
}

export function PrimaryFooter({ children }: Props) {
  return (
    <footer className={styles.footer}>
      <Image src={asset(BrawnLogo)} className={styles.logo} alt="Brawn-In-Gym" />
      {children}
    </footer>
  );
}

export default PrimaryFooter;

import { useCallback, useEffect, useRef } from 'react';
import { useMouseGestures } from '../../../helpers/hooks';
import styles from './styles.module.scss';

interface Props {
  durationMs: number;
  onComplete?: () => void;
  disabled?: boolean;
}

export function Timer({ durationMs, onComplete, disabled = false }: Props) {
  const valueEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!valueEl.current) {
      return;
    }

    if (disabled) {
      valueEl.current.classList.remove(styles.animate);
    } else {
      valueEl.current.classList.add(styles.animate);
    }
  });

  const skipAnimation = useCallback(() => {
    if (valueEl.current) {
      valueEl.current.classList.remove(styles.animate);
    }

    if (onComplete) {
      requestAnimationFrame(() => onComplete());
    }
  }, [onComplete]);

  const toggleAnimation = useCallback(() => {
    if (valueEl.current) {
      valueEl.current.style.animationPlayState =
        valueEl.current.style.animationPlayState === 'paused' ? 'running' : 'paused';
    }
  }, []);

  useMouseGestures({
    onClick: toggleAnimation,
    onDoubleClick: skipAnimation,
  });

  return (
    <div className={styles.timer}>
      <div
        ref={valueEl}
        className={styles.value}
        onAnimationEnd={(e) =>
          e.animationName === styles.progressOut && !disabled && skipAnimation()
        }
        style={{
          animationDuration: `${durationMs}ms, 1000ms`,
          animationDelay: `0ms, ${durationMs}ms`,
        }}
      />
    </div>
  );
}

export default Timer;

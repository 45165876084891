import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import SlideLayout from '../../../layout/slide';
import MilestonePrizeProgress, {
  MilestonePrize,
} from '../../../../components/milestones/milestonePrizeProgress';
import styles from './styles.module.scss';

export interface PrizeProgressSlide extends Slide {
  key: 'prizeProgress';
  items: MilestonePrize[];
}

export function PrizeProgress(props: SlideProps<PrizeProgressSlide>) {
  const slide = useSlide(props, {
    enterDuration: 4500,
    updateDuration: 500,
    leaveDuration: 5200,
  });

  const { stage } = props;

  return (
    <SlideLayout>
      <div className={styles.prizeProgressSlide}>
        <MilestonePrizeProgress
          milestones={slide.items}
          mounted={stage === 'entering' || stage === 'mounted'}
        />
      </div>
    </SlideLayout>
  );
}

export default PrizeProgress;

import TabLayout from '../layout/tab';
import { SlideKey } from '../../models/whiteboard';
import { SlideProps, Slide } from '../../models';
import Slides from './slides';
import styles from './styles.module.scss';
import { forwardRef } from 'react';

export const WhiteboardTab = forwardRef<HTMLDivElement, SlideProps<Slide>>(
  ({ current, previous, stage, onEnter, onLeave, ...props }, ref) => {
    // TODO: work out the types
    const Slide = Slides[current.key as SlideKey];

    return (
      <TabLayout ref={ref} className={styles.whiteboardTab} {...props}>
        <Slide
          current={current}
          previous={previous}
          stage={stage}
          onEnter={onEnter}
          onLeave={onLeave}
        />
      </TabLayout>
    );
  },
);

export default WhiteboardTab;

import { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { animated, easings, Globals, useSpring } from 'react-spring';
import { ReactComponent as ChangeIcon } from '../../../assets/images/svgs/chevron.svg';
import { ReactComponent as NoChangeIcon } from '../../../assets/images/svgs/dash.svg';
import { Score } from '../../../models/whiteboard';

interface Props {
  rank: number;
  score?: Score;
  mounted?: boolean;
  rankBy: string;
}

const cx = classNames.bind(styles);

export function SecondaryBoardInfoLine({ rank, score, rankBy, mounted = false }: Props) {
  // animation to show end of name
  const [flip, setFlip] = useState(false);
  const nameRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const el = nameRef.current;

    if (!el) {
      return;
    }

    setWidth(el.offsetWidth);
  }, []);

  const { scroll } = useSpring({
    scroll: mounted && !Globals.skipAnimation ? width : 0,
    from: { scroll: 0 },
    reset: true,
    reverse: flip,
    delay: !flip ? 3000 : 0,
    config: {
      duration: 4000,
      easing: easings.easeInOutCubic,
    },
    onRest: () => {
      if (Globals.skipAnimation) {
        return;
      }

      if (mounted) {
        setFlip(!flip);
      }
    },
  });

  return (
    <div className={styles.secondaryBoardInfoLine}>
      <div
        className={cx('change', { up: score?.change === 'up' }, { down: score?.change === 'down' })}
      >
        {!score?.change || score.change === 'none' ? <NoChangeIcon /> : <ChangeIcon />}
      </div>
      <div className={styles.rank}>#{rank}</div>
      <animated.div
        className={styles.nameScroller}
        scrollLeft={scroll}
        style={{
          overflow: 'auto', // needs to be inline for spring
        }}
      >
        <div className={styles.nameHolder} ref={nameRef}>
          <span className={styles.name}>{score?.name || ''}</span>
        </div>
        <div className={styles.fade} />
      </animated.div>
      <div className={styles.liftInfo}>
        <div className={styles.amountInfo}>
          <span className={styles.amount}>{score?.value.toLocaleString() || ''} </span>
          {<span className={styles.unit}>{score?.unit || ''}</span>}
        </div>
        <div className={styles.repSetInfo}>
          {rankBy !== 'weight' && score?.weight}
          <span style={{ textTransform: 'none' }}>
            {rankBy !== 'weight' && score?.weight ? score?.weightUnit : ''}
          </span>
          <span style={{ textTransform: 'none' }}>
            {rankBy !== 'weight' && score?.weight && score?.reps ? ' x ' : ''}
          </span>
          {score?.reps || ''}
          <span style={{ textTransform: 'none' }}>
            {rankBy === 'weight' && score?.weight && score?.reps && score?.sets ? ' x ' : ''}
          </span>
          {rankBy === 'weight' && score?.sets ? score.sets : ''}
        </div>
      </div>
    </div>
  );
}

export default SecondaryBoardInfoLine;

import { useState } from 'react';
import styles from './styles.module.scss';
import Countdown from '../countdown';
import plural from '../../helpers/plural';
import dayjs from 'dayjs';
import { useCountdown } from '../../helpers/countdown';
import { animated, easings, useTransition } from 'react-spring';

interface Props {
  endDate: string | Date;
}

function wordingObj(endDate: string | Date, days: number) {
  if (days > 7) {
    // over a week left
    return {
      key: 'weeks',
      grammar: 'Ends ',
      ending: dayjs(endDate).format('DD/MM/YYYY'),
    };
  }
  if (days >= 1) {
    // over a day left
    const dayInteger = Math.floor(Number(days));
    return {
      key: 'days',
      grammar: 'Ends in ',
      ending: plural(dayInteger, 'just over a day', `${dayInteger} days`),
    };
  }
  return {
    key: 'hours',
    grammar: 'Ends ',
    ending: 'imminently',
  };
}
export function ChallengeSummaryCountdown({ endDate }: Props) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [days, hours, minutes, seconds] = useCountdown(endDate);

  const wording = wordingObj(endDate, days);
  const wordTransitions = useTransition(wording, {
    immediate: !hasAnimated,
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
    onRest() {
      setHasAnimated(true);
    },
    config: {
      duration: 1000,
      easing: easings.easeInOutQuart,
    },
    keys: wording.key,
  });

  return (
    <div className={styles.challengeSummaryCountdown}>
      {wordTransitions((springStyles, item) => (
        <animated.div className={styles.wording} style={springStyles}>
          <span>{item.grammar}</span>
          <span className={styles.highlight}>
            {item.key !== 'hours' ? (
              item.ending
            ) : (
              <Countdown hours={hours} minutes={minutes} seconds={seconds} />
            )}
          </span>
        </animated.div>
      ))}
    </div>
  );
}

export default ChallengeSummaryCountdown;

import { useState, useCallback } from 'react';
import styles from './styles.module.scss';
import { useSpring, animated } from 'react-spring';
import useFitText from 'use-fit-text';

interface Props {
  exercise: string;
  name: string;
  lifts: number;
}

export function ContributorBoxPrimary({ exercise, lifts, name }: Props) {
  const [showResizedFont, setShowResizedFont] = useState(false);

  // resize fonts and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowResizedFont(true);
  }, []);

  // name text
  const nameFitText = useFitText({ onStart, onFinish });
  const nameFontSize = nameFitText['fontSize'];
  const nameRef = nameFitText['ref'];
  const nameSpring = useSpring({
    immediate: true,
    to: {
      opacity: showResizedFont ? 1 : 0,
      display: showResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  return (
    <div className={styles.contributorBoxPrimary}>
      <div className={styles.titleHolder}>
        <h3 className={styles.title}>{lifts === 1 ? exercise : lifts + ' Lifts'}</h3>
        <div className={styles.decoration} />
      </div>
      <div className={styles.nameHolder}>
        <animated.div
          ref={nameRef}
          className={styles.name}
          style={{ fontSize: nameFontSize, ...nameSpring }}
        >
          {name}
        </animated.div>
      </div>
    </div>
  );
}

export default ContributorBoxPrimary;

import { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useSpring, animated, easings, Globals } from 'react-spring';
import useFitText from 'use-fit-text';
import Image from '../../image';

interface Props {
  mainTitle: string;
  subTitle?: string;
  additionalText?: string;
  imageUrl: string;
  mounted: boolean;
}

const cx = classNames.bind(styles);

export function PrizeInfo({
  mainTitle,
  subTitle = '',
  additionalText = '',
  imageUrl,
  mounted,
}: Props) {
  const [showResizedFont, setShowResizedFont] = useState(false);

  // break the mainTitle into smaller words if necessary
  const formattedText = mainTitle
    .split(' ')
    .flatMap((word) => {
      if (word.length > 8) {
        const part1 = word.substring(0, 5).concat('-');
        const part2 = word.substring(5);
        return [part1, `${part2} `];
      }
      return [`${word} `];
    })
    .join('')
    .slice(0, -1);

  // resize mainTitle and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowResizedFont(true);
  }, []);
  const { fontSize, ref } = useFitText({ onStart, onFinish });

  // animation to show mainTitle
  const mainTitleSpring = useSpring({
    immediate: true,
    to: {
      opacity: showResizedFont ? 1 : 0,
      display: showResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);

  // gateway animation
  const gatewayOpacity = useSpring({
    to: {
      opacity: mounted ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuart,
    },
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setHasMountedAnimatedIn(!hasMountedAnimatedIn);
    },
  });
  const gatewayTextBlock = useSpring({
    to: {
      transform: mounted
        ? 'translateX(0%)'
        : `translateX(${hasMountedAnimatedIn ? '-100%' : '100%'})`,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutCubic,
    },
  });
  const gatewayImage = useSpring({
    to: {
      transform: mounted
        ? 'translateX(0%)'
        : `translateX(${hasMountedAnimatedIn ? '-50%' : '50%'})`,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <animated.div className={styles.prizeInfo} style={gatewayOpacity}>
      <animated.div className={styles.background} style={gatewayTextBlock} />
      <animated.div className={styles.prizeImageHolder} style={gatewayImage}>
        <Image className={styles.prizeImage} src={imageUrl} />
      </animated.div>
      <animated.div className={styles.foreground} style={gatewayTextBlock}>
        <div className={styles.foregroundBg} />
        <h3 className={styles.title}>
          <span className={styles.prefix}>
            <em className={styles.emphasis}>We've</em> earned a
          </span>
          <div className={styles.mainTitleHolder}>
            <animated.div
              ref={ref}
              className={styles.mainTitle}
              style={{ fontSize, ...mainTitleSpring }}
            >
              {formattedText}
            </animated.div>
          </div>
          {subTitle && <span className={styles.suffix}>{subTitle}</span>}
        </h3>
        {additionalText && (
          <span className={cx('emphasis', 'additionalText')}>{additionalText}</span>
        )}
      </animated.div>
    </animated.div>
  );
}

export default PrizeInfo;

import styles from './styles.module.scss';
import SecondaryBoardInfoLine from '../secondaryBoardInfoLine';
import { Score } from '../../../models/whiteboard';

interface Props {
  scores: Score[]; // order relates to ranking (e.g. scores[0] = score in first place)
  mounted: boolean;
  rankBy: string;
}

export function SecondaryBoardInfo({ scores, rankBy, mounted = false }: Props) {
  return (
    <div className={styles.secondaryBoardInfo}>
      {[...Array(4)].map((_, index) => (
        <div className={styles.score} key={index}>
          <SecondaryBoardInfoLine
            score={scores[index]}
            rank={index + 2}
            mounted={mounted}
            rankBy={rankBy}
          />
        </div>
      ))}
    </div>
  );
}

export default SecondaryBoardInfo;

import { memo } from 'react';
import { SlideKey } from '../../../models/showcase';
import ShoutOut from './shoutOut';
//import { Slide, SlideComponent } from '../../../models';

// TODO: add typescript types for the SlideComponent<Slide>
const slides: Record<SlideKey, any> = {
  shoutOut: memo(ShoutOut),
};

export default slides;

import Authenticator from './authenticator';
import {
  Group,
  CommunityChallenge,
  RecentContributor,
  TopSet,
  LeaderboardPosition,
  ShoutOut,
} from '../models';

type DTO<T> = { data: T };

export class Client {
  private authenticator: Authenticator;

  constructor(authenticator: Authenticator) {
    this.authenticator = authenticator;
  }

  async getGroup(id: string): Promise<Group> {
    return (await this.authenticator.fetch<DTO<Group>>(`/api/groups/${id}`)).data;
  }

  async getPreviousChallenge(groupId: string): Promise<CommunityChallenge | undefined> {
    const response = await this.authenticator.fetch<DTO<CommunityChallenge> | undefined>(
      `/api/group/${groupId}/challenge/previous`,
    );

    return response?.data;
  }

  async getCurrentChallenge(groupId: string): Promise<CommunityChallenge | undefined> {
    const response = await this.authenticator.fetch<DTO<CommunityChallenge> | undefined>(
      `/api/group/${groupId}/challenge/current`,
    );

    return response?.data;
  }

  async getNextChallenge(groupId: string): Promise<CommunityChallenge | undefined> {
    const response = await this.authenticator.fetch<DTO<CommunityChallenge> | undefined>(
      `/api/group/${groupId}/challenge/next`,
    );

    return response?.data;
  }

  async getRecentContributors(challengeId: string): Promise<RecentContributor[]> {
    const response = await this.authenticator.fetch<DTO<RecentContributor[]> | undefined>(
      `/api/group-challenge/${challengeId}/recent-contributors?per_page=6`,
    );

    return response?.data.slice(0, 6) || [];
  }

  async getLeaderboardTopSets(groupId: string, position: LeaderboardPosition): Promise<TopSet[]> {
    return (
      await this.authenticator.fetch<DTO<TopSet[]>>(
        `/api/group/${groupId}/leaderboard/${position}/rankings`,
      )
    ).data;
  }

  async getShoutOuts(groupId: string): Promise<ShoutOut[]> {
    const response = await this.authenticator.fetch<DTO<ShoutOut[]>>(
      `/api/group/${groupId}/shout-outs?per_page=5`,
    );

    return response.data.slice(0, 5);
  }
}

export function createClient(): Promise<Client> {
  return Promise.resolve(new Client(new Authenticator()));
}

import { useState, useCallback } from 'react';
import { Screen, TabName } from '../../models';
export { createScreenSource as createRemoteScreenSource } from './remote';
export { createScreenSource as createStaticScreenSource } from './static';

export interface ScreenSource {
  rewind: () => void;
  next: () => Screen;
  visibleTabs: () => Set<TabName>;
}

export function useScreen(source: ScreenSource): [Screen, () => void] {
  const [screen, setScreen] = useState<Screen>(() => {
    source.rewind();
    return source.next();
  });

  const next = useCallback(() => {
    // we clone the screen to always set the 'screen' to a new object
    // which allows us to keep showing the same slide in circles if needed
    setScreen({ ...source.next() });
  }, [source]);

  return [screen, next];
}

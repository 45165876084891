import { memo, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useSpring, animated, easings, Globals } from 'react-spring';
import pxToRem from '../../helpers/pxToRem';

interface Props {
  val: number;
  height: number; // px for 'window'
  fontSize: number; // px for font
  lineHeight: number; // how central the number is inside the 'window'
  duration?: number; // how fast the rolling animation occurs
  reverse?: boolean; // if we should reverse the default direction of roll
}

const cx = classNames.bind(styles);

// Create array of numbers to roll through.
function rollingNumbers(n: number, reverse: boolean = false): number[] {
  return reverse
    ? Array.from(Array(10), (_, x) => (n + 10 - x) % 10)
    : Array.from(Array(10), (_, x) => (x + n) % 10);
}

export const RollingNumber = memo(
  ({ val, height, fontSize, lineHeight, duration = 2000, reverse = false }: Props) => {
    // Set height of 'window'
    const heightRelative = pxToRem(height);
    const heightInRem = pxToRem(height, true);

    // Set the numbers to scroll through (runs on initial load only)
    const [numbers, setNumbers] = useState(() => {
      return rollingNumbers(val, reverse);
    });

    // Get the index to scroll to
    const toIndex = numbers.indexOf(val);

    const springProps = useSpring({
      immediate: toIndex === 0, // no animation needed if we are already in position
      to: {
        transform: reverse
          ? `translateY(${Number(heightRelative) * toIndex}rem)`
          : `translateY(${-(Number(heightRelative) * toIndex)}rem)`,
      },
      from: {},
      onRest() {
        if (Globals.skipAnimation) {
          return;
        }

        // when animation has finished
        setTimeout(() => {
          // timeout ensures we don't see flicker
          setNumbers(rollingNumbers(val, reverse)); // update DOM with new list of numbers so it starts at new val
        }, 0);
      },
      config: {
        duration,
        easing: easings.easeInOutQuart,
      },
    });

    return (
      <div>
        <div
          className={cx('rollingNumber', { reverse })}
          style={{
            height: heightInRem,
            fontSize: pxToRem(fontSize, true),
            lineHeight: pxToRem(lineHeight, true),
          }}
        >
          {numbers.map((number, i) => (
            <animated.div className={styles.digit} key={i} style={springProps}>
              {number}
            </animated.div>
          ))}
        </div>
      </div>
    );
  },
);

export default RollingNumber;

import styles from './styles.module.scss';
import PrimaryBoardInfo from '../primaryBoardInfo';
import SecondaryBoardInfo from '../secondaryBoardInfo';
import { Score } from '../../../models/whiteboard';

export interface Props {
  type: string;
  scores: Score[]; // order relates to rankin (e.g. scores[0] = score in first place)
  mounted: boolean;
  rankBy: string;
}

export function Board({ type, scores, rankBy, mounted = false }: Props) {
  const secondaryLifters = scores.slice(1);

  return (
    <div className={styles.board}>
      <div className={styles.primary}>
        <PrimaryBoardInfo type={type} score={scores[0]} rankBy={rankBy} />
      </div>
      <div className={styles.secondary}>
        <SecondaryBoardInfo scores={secondaryLifters} mounted={mounted} rankBy={rankBy} />
      </div>
    </div>
  );
}

export default Board;

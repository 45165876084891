import { useState } from 'react';
import { useSpring, useSprings, animated, easings } from 'react-spring';
import { Statistic } from '../../../models/whiteboard';
import BoardComponent from '../board';
import styles from './styles.module.scss';

interface Props {
  boards: Statistic[];
  mounted?: boolean;
}

export function BoardList({ boards, mounted = false }: Props) {
  // has mounted animated in yet (used to determine direction for gateway spring values)
  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);

  const gatewayBoardList = useSpring({
    from: {
      transform: 'translateX(18rem)', // may need to tweak these values when placed on slide
      opacity: 0,
    },
    to: {
      transform: mounted ? 'translateX(0rem)' : `translateX(${hasMountedAnimatedIn ? -18 : 18}rem)`,
      opacity: mounted ? 1 : 0,
    },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
    onRest() {
      setHasMountedAnimatedIn(mounted);
    },
  });

  const [gatewayBoards] = useSprings(
    boards.length,
    (i) => ({
      from: {
        transform: `translateX(${3.75 * (i + 1)}rem)`,
      },
      to: {
        transform: mounted ? 'translateX(0rem)' : `translateX(${3.75 * i}rem)`,
      },
      config: {
        duration: 2500,
        easing: easings.easeInOutCubic,
      },
    }),
    [boards, mounted],
  );

  return (
    <animated.div className={styles.boardList} style={gatewayBoardList}>
      {gatewayBoards.map((gatewayBoardsStyles, index) => (
        <animated.div className={styles.board} key={index} style={gatewayBoardsStyles}>
          <BoardComponent
            type={boards[index].type}
            scores={boards[index].scores}
            mounted={mounted && hasMountedAnimatedIn}
            rankBy={boards[index].rankBy}
          />
        </animated.div>
      ))}
    </animated.div>
  );
}

export default BoardList;

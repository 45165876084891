import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface Props extends React.ComponentProps<'div'> {
  children: React.ReactNode;
}

export const Tab = forwardRef<HTMLDivElement, Props>(
  ({ children, className = '', ...props }, ref) => {
    return (
      <div ref={ref} className={classNames(styles.tab, className)} {...props}>
        {children}
      </div>
    );
  },
);

export default Tab;

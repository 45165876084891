import dayjs from 'dayjs';
import { ScreenFamily } from './screenFamily';
import { ScreenFamilySourceBase } from './screenFamilySource';
import { Client } from '../../../api';
import { findLastIndex } from '../../../helpers/findLastIndex';
import { computeMilestones, describeMilestone } from '../../../helpers/milestones';
import {
  ChallengeMilestone,
  CommunityChallenge,
  Group,
  RecentContributor,
  Screen,
} from '../../../models';
import { PrizeAwardedSlide } from '../../../tabs/challenge/slides/prizeAwarded';
import { PrizeProgressSlide } from '../../../tabs/challenge/slides/prizeProgress';
import { TargetWeightSlide } from '../../../tabs/challenge/slides/targetWeight';
import { NextChallengeSlide } from '../../../tabs/challenge/slides/nextChallenge';
import { RecentContributorsSlide } from '../../../tabs/challenge/slides/recentContributors';

export default class ChallengeScreenFamilySource extends ScreenFamilySourceBase {
  private client: Client;
  private group: Group;
  private targetWeightScreenFactory: TargetWeightScreenFactory;
  private nextChallengeScreenFactory: NextChallengeScreenFactory;
  private prizeAwardedScreenFactory: PrizeAwardedScreenFactory;
  private prizeProgressScreenFactory: PrizeProgressScreenFactory;
  private recentContributorsScreenFactory: RecentContributorsScreenFactory;

  constructor(client: Client, group: Group) {
    super();

    this.client = client;
    this.group = group;
    this.targetWeightScreenFactory = new TargetWeightScreenFactory(group);
    this.nextChallengeScreenFactory = new NextChallengeScreenFactory();
    this.prizeAwardedScreenFactory = new PrizeAwardedScreenFactory();
    this.prizeProgressScreenFactory = new PrizeProgressScreenFactory(group);
    this.recentContributorsScreenFactory = new RecentContributorsScreenFactory(group);
  }

  protected async fetchScreenFamily(): Promise<ScreenFamily> {
    const now = dayjs();

    // current challenge may not exist, or may exist but be in the future
    // and in such cases, we need to fall back to the 'next challenge' screens
    const currentChallenge = await this.client.getCurrentChallenge(this.group.id);
    if (currentChallenge && now.isAfter(currentChallenge.start_at)) {
      return new ScreenFamily(this, await this.composeCurrentChallengeScreens(currentChallenge));
    }

    const nextChallenge = currentChallenge || (await this.client.getNextChallenge(this.group.id));
    if (nextChallenge) {
      return new ScreenFamily(this, await this.composeNextChallengeScreens(nextChallenge));
    }

    return Promise.resolve(new ScreenFamily(this, []));
  }

  private async composeCurrentChallengeScreens(challenge: CommunityChallenge): Promise<Screen[]> {
    const recentContributors = await this.client.getRecentContributors(challenge.id);

    return Promise.resolve(
      [
        this.targetWeightScreenFactory.create(challenge),
        this.prizeAwardedScreenFactory.create(challenge),
        // this.prizeProgressScreenFactory.create(challenge),
        this.recentContributorsScreenFactory.create(recentContributors),
      ].filter((screen): screen is Screen => !!screen),
    );
  }

  private async composeNextChallengeScreens(challenge: CommunityChallenge): Promise<Screen[]> {
    const screens: Screen[] = [];

    const prevChallenge = await this.client.getPreviousChallenge(this.group.id);
    if (prevChallenge) {
      const prizeAwardedScreen = this.prizeAwardedScreenFactory.create(prevChallenge);
      if (prizeAwardedScreen) {
        screens.push(prizeAwardedScreen);
      }
    }

    screens.push(this.nextChallengeScreenFactory.create(challenge));

    return Promise.resolve(screens);
  }
}

class TargetWeightScreenFactory {
  private group: Group;
  private previousState: CommunityChallenge | undefined;

  constructor(group: Group) {
    this.group = group;
  }

  create(challenge: CommunityChallenge): Screen {
    if (challenge.id !== this.previousState?.id) {
      delete this.previousState;
    }

    const screen: Screen = {
      tab: { key: 'challenge' },
      slide: this.createSlide(challenge),
      previousSlide: this.previousState ? this.createSlide(this.previousState) : undefined,
      durationMs: 5000,
    };

    this.previousState = challenge;

    return screen;
  }

  private createSlide(challenge: CommunityChallenge): TargetWeightSlide {
    const weightUnit = this.group.unit_preference === 'kg' ? 'kg' : 'lbs';
    const weight = weightUnit === 'kg' ? challenge.current : challenge.current_pounds;
    const milestoneTargets = challenge.milestones.map((milestone) => {
      return {
        target: weightUnit === 'kg' ? milestone.target : milestone.target_pounds,
      };
    });

    return {
      key: 'targetWeight',
      weight: weight,
      weightUnit: weightUnit,
      progress: challenge.progress,
      contributors: challenge.contributors,
      startsAt: challenge.start_at,
      endsAt: challenge.achieve_by,
      milestones: computeMilestones({
        weight: weight,
        weightUnit: weightUnit,
        milestones: milestoneTargets,
      }),
      title: challenge.name,
    };
  }
}

class NextChallengeScreenFactory {
  create(challenge: CommunityChallenge): Screen {
    return {
      tab: { key: 'challenge' },
      slide: this.createSlide(challenge),
      durationMs: 5000,
    };
  }

  private createSlide(challenge: CommunityChallenge): NextChallengeSlide {
    return {
      key: 'nextChallenge',
      startDate: challenge.start_at,
    };
  }
}

class PrizeAwardedScreenFactory {
  create(challenge: CommunityChallenge): Screen | undefined {
    const milestone = this.lastAchievedMilestoneWithPrize(challenge);

    if (!milestone) {
      return undefined;
    }

    return {
      tab: { key: 'challenge' },
      slide: this.createSlide(milestone),
      durationMs: 5000,
    };
  }

  private createSlide(milestone: ChallengeMilestone): PrizeAwardedSlide {
    return {
      key: 'prizeAwarded',
      title: milestone.name,
      subtitle: milestone.subtitle,
      content: milestone.tagline,
      imageUrl: milestone.img_url,
    };
  }

  private lastAchievedMilestoneWithPrize(
    challenge: CommunityChallenge,
  ): ChallengeMilestone | undefined {
    const index = findLastIndex(
      challenge.milestones,
      (milestone) => milestone.is_prize && Boolean(milestone.achieved_at),
    );

    if (index < 0) {
      return undefined;
    }

    return challenge.milestones[index];
  }
}

class PrizeProgressScreenFactory {
  private group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  create(challenge: CommunityChallenge): Screen {
    const slide = this.createSlide(challenge);

    return {
      tab: { key: 'challenge' },
      slide: slide,
      durationMs: 5000,
    };
  }

  private createSlide(challenge: CommunityChallenge): PrizeProgressSlide {
    const weightUnit = this.group.unit_preference === 'kg' ? 'kg' : 'lbs';
    const weight = weightUnit === 'kg' ? challenge.current : challenge.current_pounds;
    const milestoneTargets = challenge.milestones.map((milestone) => {
      return {
        target: weightUnit === 'kg' ? milestone.target : milestone.target_pounds,
      };
    });

    return {
      key: 'prizeProgress',
      items: computeMilestones({
        weight: weight,
        weightUnit: weightUnit,
        milestones: milestoneTargets,
      }).map((milestone, index: number) => {
        const challengeMilestone = challenge.milestones[index - 1];

        return {
          milestone: describeMilestone(milestone),
          prize: challengeMilestone && {
            mainTitle: challengeMilestone.name,
            subTitle: challengeMilestone.subtitle,
            additionalText: challengeMilestone.tagline,
            imageUrl: challengeMilestone.img_url,
            achieved: Boolean(challengeMilestone.achieved_at),
          },
        };
      }),
    };
  }
}

class RecentContributorsScreenFactory {
  private group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  create(recentContributors: RecentContributor[]): Screen | undefined {
    if (!recentContributors.length) {
      return undefined;
    }

    const slide = this.createSlide(recentContributors);

    return {
      tab: { key: 'challenge' },
      slide: slide,
      durationMs: 10000,
    };
  }

  private createSlide(recentContributors: RecentContributor[]): RecentContributorsSlide {
    const weightUnit = this.group.unit_preference === 'kg' ? 'kg' : 'lbs';

    return {
      key: 'recentContributors',
      contributors: recentContributors.map((contributor) => {
        const weight = contributor.topSets.reduce((previousValue, topSet) => {
          const weight = weightUnit === 'kg' ? topSet.weight : topSet.weight_pounds;
          return previousValue + weight * topSet.reps * topSet.sets;
        }, 0);

        const lifts = contributor.topSets
          .map((topSet) => topSet.lift_variation.name)
          .filter((value, index, self) => self.indexOf(value) === index).length;
        let exercise = contributor.topSets[0].lift_variation.name;
        return {
          exercise,
          name: `${contributor.first_name} ${contributor.last_name}`,
          weight,
          weightUnit,
          lifts,
        };
      }),
    };
  }
}

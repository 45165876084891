import { ScreenFamily } from './screenFamily';
import { ScreenFamilySourceBase } from './screenFamilySource';
import {
  Group,
  MostNumberShoutOut,
  Screen,
  ShoutOut as ShoutOutDTO,
  TargetHitShoutOut,
} from '../../../models';
import { Client } from '../../../api';
import { ShoutOutSlide } from '../../../tabs/showcase/slides/shoutOut';
import { ShoutOut, ShoutOutMetricReason } from '../../../models/showcase';

export default class ShowcaseScreenFamilySource extends ScreenFamilySourceBase {
  private client: Client;
  private group: Group;
  private shoutOutScreenFactory: ShoutOutScreenFactory;

  constructor(client: Client, group: Group) {
    super();

    this.client = client;
    this.group = group;
    this.shoutOutScreenFactory = new ShoutOutScreenFactory(group);
  }

  protected async fetchScreenFamily(): Promise<ScreenFamily> {
    const screens: Screen[] = [];

    (await this.client.getShoutOuts(this.group.id)).forEach((shoutOut) => {
      screens.push(this.shoutOutScreenFactory.create(shoutOut));
    });

    return new ScreenFamily(this, screens);
  }
}

class ShoutOutScreenFactory {
  private group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  create(shoutOut: ShoutOutDTO): Screen {
    return {
      tab: { key: 'showcase' },
      slide: this.createSlide(shoutOut),
      durationMs: 6000,
    };
  }

  private createSlide(shoutOut: ShoutOutDTO): ShoutOutSlide {
    return {
      key: 'shoutOut',
      name: `${shoutOut.user.first_name} ${shoutOut.user.last_name}`,
      shoutOut:
        shoutOut.reason === 'shout_out_milestone'
          ? this.createTargetHitShoutOut(shoutOut)
          : this.createMostNumberShoutOut(shoutOut),
    };
  }

  private createMostNumberShoutOut(shoutOut: MostNumberShoutOut): ShoutOut {
    if (shoutOut.reason === 'most_volume_this_week') {
      return {
        type: 'mostNumber',
        reason: 'volume',
        value: shoutOut.data.find((item) => item.unit === this.group.unit_preference)?.value || 0,
        weightUnit: this.group.unit_preference === 'kg' ? 'kg' : 'lbs',
      };
    }

    const reasons: Record<string, ShoutOutMetricReason> = {
      total_sets: 'sets',
      total_reps: 'reps',
      total_sessions: 'sessions',
    };

    return {
      type: 'mostNumber',
      reason: reasons[shoutOut.data[0].key],
      value: shoutOut.data[0].value,
    };
  }

  private createTargetHitShoutOut(shoutOut: TargetHitShoutOut): ShoutOut {
    if (shoutOut.shout_out_milestone.attribute === 'volume') {
      return {
        type: 'targetHit',
        reason: shoutOut.shout_out_milestone.attribute,
        value:
          this.group.unit_preference === 'kg'
            ? shoutOut.shout_out_milestone.target_kg
            : shoutOut.shout_out_milestone.target_pounds,
        weightUnit: this.group.unit_preference === 'kg' ? 'kg' : 'lbs',
      };
    }

    return {
      type: 'targetHit',
      reason: shoutOut.shout_out_milestone.attribute,
      value: shoutOut.shout_out_milestone.target,
    };
  }
}

import { useTransition, animated, easings } from 'react-spring';
import { useSlide } from '../../../../helpers/slides';
import { Slide, SlideProps } from '../../../../models';
import { Milestone } from '../../../../models/challenge';
import ChallengeSummary from '../../../../components/challengeSummary';
import MilestoneProgress from '../../../../components/milestones/milestoneProgress';
import PercentageIndicator from '../../../../components/percentageIndicator';
import CelebrationOverlay from '../../../../components/celebrationOverlay';
import SlideLayout from '../../../layout/slide';
import styles from './styles.module.scss';
import { describeMilestones } from '../../../../helpers/milestones';

export interface TargetWeightSlide extends Slide {
  weight: number;
  weightUnit: 'kg' | 'lbs';
  progress: number;
  contributors: number;
  startsAt: string;
  endsAt?: string;
  milestones: Milestone[];
  title: string;
}

function useFanfare(props: SlideProps<TargetWeightSlide>): Milestone | undefined {
  if (!props.previous) {
    return undefined;
  }

  if (props.current.milestones.length !== props.previous.milestones.length) {
    return undefined;
  }

  for (let i = props.current.milestones.length - 1; i >= 0; i--) {
    const prevMilestone = props.previous.milestones[i];
    const milestone = props.current.milestones[i];

    if (prevMilestone.target !== milestone.target) {
      return undefined;
    }

    if (milestone.progress === 100) {
      if (prevMilestone.progress < milestone.progress) {
        return milestone;
      }

      return undefined;
    }
  }

  return undefined;
}

export function TargetWeight(props: SlideProps<TargetWeightSlide>) {
  const achievedMilestone = useFanfare(props);

  const slide = useSlide(props, {
    enterDuration: 4500,
    updateDuration: 3500,
    leaveDuration: 4500,
  });

  const { stage } = props;

  const centralTransitions = useTransition(stage !== 'leaving' && stage !== 'unmounting', {
    from: {
      opacity: 0,
      transformChallengeSummary: 'translateX(33%)',
      transformPercentageIndicator: 'translateX(50%)',
    },
    enter: {
      opacity: 1,
      transformChallengeSummary: 'translateX(0%)',
      transformPercentageIndicator: 'translateX(0%)',
      delay: 600,
    },
    leave: {
      opacity: 0,
      transformChallengeSummary: 'translateX(-35%)',
      transformPercentageIndicator: 'translateX(-140%)',
      delay: 1800,
    },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
  });

  const milestoneTransitions = useTransition(stage !== 'leaving' && stage !== 'unmounting', {
    from: {
      opacity: 0,
      transform: 'translateX(-10%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(-30%)',
      delay: 2000,
    },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <SlideLayout>
      {centralTransitions(
        (springProps, showing) =>
          showing && (
            <animated.div
              className={styles.centralContentWrapper}
              style={{
                opacity: springProps.opacity,
              }}
            >
              <animated.div
                className={styles.challengeSummaryWrapper}
                style={{
                  transform: springProps.transformChallengeSummary,
                }}
              >
                <ChallengeSummary
                  weight={slide.weight}
                  weightUnit={slide.weightUnit}
                  contributors={slide.contributors}
                  startDate={slide.startsAt}
                  endDate={slide.endsAt}
                  reveal={stage === 'entering' || stage === 'mounted'}
                  title={slide.title}
                />
              </animated.div>
              <animated.div
                className={styles.percentageIndicatorWrapper}
                style={{
                  transform: springProps.transformPercentageIndicator,
                }}
              >
                <PercentageIndicator progress={slide.progress} />
              </animated.div>
            </animated.div>
          ),
      )}
      {milestoneTransitions(
        (springProps, showing) =>
          showing && (
            <animated.div className={styles.milestoneProgressWrapper} style={springProps}>
              <MilestoneProgress
                milestones={describeMilestones(slide.milestones)}
                mounted={stage === 'entering' || stage === 'mounted'}
              />
            </animated.div>
          ),
      )}
      {achievedMilestone && (
        <CelebrationOverlay
          weight={achievedMilestone.target}
          weightUnit={achievedMilestone.targetUnit}
          mounted={stage === 'mounted'}
        />
      )}
    </SlideLayout>
  );
}

export default TargetWeight;

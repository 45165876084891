import { memo } from 'react';
import { SlideKey } from '../../../models/whiteboard';
import Statistics from './statistics';
//import { Slide, SlideComponent } from '../../../models';

// TODO: add typescript types for the SlideComponent<Slide>
const slides: Record<SlideKey, any> = {
  statistics: memo(Statistics),
};

export default slides;

import { useEffect, useState } from 'react';
import { ScreenSource, createStaticScreenSource, createRemoteScreenSource } from '../screenSource';
import { Group } from '../../models';
import { createClient as loadClient } from '../../api';
import exampleGroupLogo from '../../assets/images/svgs/HYROX-Logo.svg';
import loadFont from './font';
import asset from '../../helpers/asset';

const loadAssets = () => {
  return Promise.all([loadFont('Barlow')]);
};

interface Props {
  groupId: string;
  children: (group: Group, screenSource: ScreenSource) => JSX.Element;
}

interface AppDeps {
  group: Group;
  screenSource: ScreenSource;
}

export default function Loader({ groupId, children }: Props) {
  const [appDeps, setAppDeps] = useState<AppDeps | undefined>();

  useEffect(() => {
    async function loadAppDeps(): Promise<AppDeps> {
      if (groupId === 'static') {
        return {
          group: {
            id: groupId,
            name: 'Implexus',
            unit_preference: 'kg',
            logo_for_dark_background_url: asset(exampleGroupLogo),
            leaderboards: [],
          },
          screenSource: await createStaticScreenSource(),
        };
      }

      const client = await loadClient();
      const group = await client.getGroup(groupId);
      const screenSource = await createRemoteScreenSource(client, group);

      await screenSource.seed();

      return { group, screenSource };
    }

    async function loadAll() {
      await loadAssets();
      await loadAppDeps().then((appDeps) => setAppDeps(appDeps));
    }

    loadAll();
  }, [groupId]);

  if (!appDeps) {
    return null;
  }

  return children(appDeps.group, appDeps.screenSource);
}

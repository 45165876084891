import { useState } from 'react';
import { animated, easings, useSpring, useTransition } from 'react-spring';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import WeightIndicator, { WeightUnit } from '../weightIndicator';
import ChallengeSummaryCountdown from '../challengeSummaryCountdown';
import dayjs from 'dayjs';

interface Props {
  weight: number;
  weightUnit: WeightUnit;
  contributors: number;
  startDate: string;
  endDate?: string;
  reveal?: boolean;
  title: string;
}

const cx = classNames.bind(styles);

function splitToDigits(contributors: number) {
  const contributorDigits = contributors.toString().split('');
  return contributorDigits.map((digit, i) => ({
    key: contributorDigits.length - 1 - i, // helps digits keep their original keys regardless how many digits there are
    val: Number(digit),
  }));
}

export function ChallengeSummary({
  weight,
  weightUnit,
  contributors,
  startDate,
  endDate = undefined,
  reveal = false,
  title,
}: Props) {
  const [hasAnimated, setHasAnimated] = useState(false);

  // numbered progress animation
  const contributorDigits = splitToDigits(contributors);
  const listTransitions = useTransition(title, {
    immediate: !hasAnimated,
    from: { opacity: 0, transform: 'translate3d(0%, 100%, 0px) ', maxWidth: '0' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0px, 0px) ', maxWidth: '2000px' },
    leave: { opacity: 0, transform: 'translate3d(25%, 0px, 0px)', maxWidth: '0' },
    onRest() {
      setHasAnimated(true);
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuart,
    },
    keys: contributorDigits.map((item) => item.key),
  });

  // reveal animations
  const infoReveal = useSpring({
    from: {
      transformTitle: 'translateY(140%)',
      transformFooter: 'translateY(-170%)',
    },
    to: {
      transformTitle: reveal ? 'translateY(0%)' : 'translateY(140%)',
      transformFooter: reveal ? 'translateY(0%)' : 'translateY(-170%)',
    },
    config: {
      duration: reveal ? 1000 : 1500,
      easing: easings.easeOutQuint,
    },
    delay: reveal ? 2500 : 500,
  });

  return (
    <div className={styles.challengeSummary}>
      <animated.h3 className={styles.title} style={{ transform: infoReveal.transformTitle }}>
        <span className={cx('contributors')}>
          {listTransitions((springStyles, item) => (
            <animated.span style={springStyles}>
              <span>{item}</span>
            </animated.span>
          ))}
        </span>
      </animated.h3>
      <div className={styles.main}>
        <WeightIndicator weight={weight} weightUnit={weightUnit} />
      </div>
      <animated.div className={styles.period} style={{ transform: infoReveal.transformFooter }}>
        <div className={styles.started}>
          <span>Started</span>{' '}
          <span className={styles.highlight}>{dayjs(startDate).format('DD/MM/YYYY')}</span>
        </div>
        {endDate && <ChallengeSummaryCountdown endDate={endDate} />}
      </animated.div>
    </div>
  );
}

export default ChallengeSummary;

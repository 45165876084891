import classNames from 'classnames/bind';
import { memo } from 'react';
import RollingNumber from '../rollingNumber';
import styles from './styles.module.scss';

interface Props {
  set1: number;
  set2: number;
  set3: number;
  separator: string;
}

const cx = classNames.bind(styles);

function splitToCharacters(n: number | string, isSeparator: boolean) {
  const characters = isSeparator ? [n.toString()] : n.toString().padStart(2, '0').split('');
  return characters.map((character: number | string) => {
    return {
      isSeparator,
      val: isSeparator ? character : Number(character),
    };
  });
}

export const DateTimeTickerCountdown = memo(({ set1, set2, set3, separator }: Props) => {
  // collect characters
  const hoursCharacters = splitToCharacters(set1, false);
  const minutesCharacters = splitToCharacters(set2, false);
  const secondsCharacters = splitToCharacters(set3, false);
  const seperatorCharacters = splitToCharacters(separator, true);
  const allCharacters = [
    ...hoursCharacters,
    ...seperatorCharacters,
    ...minutesCharacters,
    ...seperatorCharacters,
    ...secondsCharacters,
  ];
  const allCharactersObj = allCharacters.map((character, i) => ({
    key: allCharacters.length - 1 - i,
    ...character,
  }));

  return (
    <div className={styles.dateTimeTickerCountdown}>
      <div className={cx('count', 'hours')}>
        {allCharactersObj.map((character) => (
          <div
            className={cx('character', { isSeparator: character.isSeparator })}
            key={character.key}
          >
            <div className={cx('fade', 'fadeTop')} />
            {character.isSeparator ? (
              character.val
            ) : (
              <RollingNumber
                height={241}
                fontSize={160}
                lineHeight={242}
                val={Number(character.val)}
                duration={500}
                reverse={true}
              />
            )}
            <div className={cx('fade', 'fadeBottom')} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default DateTimeTickerCountdown;

import { useMemo } from 'react';
import { animated, easings, useTransition, useSpring } from 'react-spring';
import DateTimeTickerCountdown from '../dateTimeTickerCountdown';
import styles from './styles.module.scss';
import dayjs from 'dayjs';
import { useCountdown } from '../../helpers/countdown';

interface Props {
  startDate: string | Date;
  reveal?: boolean;
}

export function NextChallengeIndicator({ startDate, reveal = false }: Props) {
  const [days, hours, minutes, seconds] = useCountdown(startDate, reveal);
  const splitDate = useMemo(() => {
    const date = dayjs(startDate);
    return {
      day: Number(date.format('DD')),
      month: Number(date.format('MM')),
      year: Number(date.format('YY')),
    };
  }, [startDate]);
  const wording = days >= 1 ? 'days' : 'hours';

  // wording animation
  const wordTransitions = useTransition(wording, {
    initial: { transform: 'translateY(0%)' },
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
    config: {
      duration: 100,
      easing: easings.easeInOutQuart,
    },
    keys: wording,
  });

  // reveal animations
  const titleEntrance = useSpring({
    from: {
      transform: 'translateY(150%)',
    },
    to: {
      transform: reveal ? 'translateY(0%)' : 'translateY(150%)',
    },
    config: {
      duration: 1000,
      easing: easings.easeOutQuint,
    },
    delay: reveal ? 1500 : 0,
  });

  return (
    <div className={styles.nextChallengeIndicator}>
      <animated.h3 className={styles.title} style={titleEntrance}>
        <span className={styles.highlight}>Next</span> Challenge Starts
      </animated.h3>
      <div className={styles.main}>
        <div className={styles.ticker}>
          {wordTransitions((springStyles, item) => (
            <animated.div className={styles.wording} style={springStyles}>
              {item === 'days' ? (
                <DateTimeTickerCountdown
                  set1={splitDate.day}
                  set2={splitDate.month}
                  set3={splitDate.year}
                  separator={'/'}
                />
              ) : (
                <DateTimeTickerCountdown
                  set1={hours}
                  set2={minutes}
                  set3={seconds}
                  separator={':'}
                />
              )}
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NextChallengeIndicator;

import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface Props {
  hours: number;
  minutes: number;
  seconds: number;
}

const cx = classNames.bind(styles);

export function Countdown({ hours, minutes, seconds }: Props) {
  const localeOptions = {
    minimumIntegerDigits: 2,
    useGrouping: false,
  };
  return (
    <span className={styles['countdown']}>
      <span className={cx('count', 'hours')}>{hours.toLocaleString('en-US', localeOptions)}</span>:
      <span className={cx('count', 'minutes')}>
        {minutes.toLocaleString('en-US', localeOptions)}
      </span>
      :
      <span className={cx('count', 'seconds')}>
        {seconds.toLocaleString('en-US', localeOptions)}
      </span>
    </span>
  );
}

export default Countdown;

import { useLayoutEffect, useRef, useState } from 'react';
import { useSprings, animated, easings, useSpring, useChain, useSpringRef } from 'react-spring';
import { findLastIndex } from '../../../helpers/findLastIndex';
import classNames from 'classnames/bind';
import MilestonePrizeComponent, { Props as MilestonePrize } from '../milestonePrize';
import styles from './styles.module.scss';

export type { Props as MilestonePrize } from '../milestonePrize';

interface Props {
  milestones: MilestonePrize[];
  mounted?: boolean;
}

const cx = classNames.bind(styles);

function determineVisibleMilestones(
  lastCompleteMilestone: number,
  milestones: object[],
): boolean[] {
  if (lastCompleteMilestone === 0) {
    return milestones.map((_: object, index: number) => {
      if (index < 3) {
        return true;
      }
      return false;
    });
  }
  if (lastCompleteMilestone < 3) {
    return milestones.map((_: object, index: number) => {
      if (index !== 0 && index < 4) {
        return true;
      }
      return false;
    });
  }
  return milestones.map((_: object, index: number) => {
    if (index < lastCompleteMilestone + 2 && index > lastCompleteMilestone - 2) {
      return true;
    }
    return false;
  });
}

function determineLastCompleteMilestone(milestones: MilestonePrize[]) {
  return findLastIndex(milestones, (item: MilestonePrize) => item.milestone.progress === 100);
}

export function MilestonePrizeProgress({ milestones, mounted = false }: Props) {
  // determine the last fully complete milestone
  const [lastCompleteMilestone] = useState(() => determineLastCompleteMilestone(milestones));

  // determine milestones which will be visible
  const [visibleMilestones] = useState(() =>
    determineVisibleMilestones(lastCompleteMilestone, milestones),
  );

  // have refs for each part so can determine position
  const partRefs = useRef<HTMLDivElement[] | null[]>([]);

  // use state to store last fully complete milestone left position
  const [positionerLeft, setPositionerLeft] = useState(0);

  // has mounted animated in yet (used to determine direction for gateway spring values)
  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);

  // update positioner when last milestone changes
  useLayoutEffect(() => {
    // show the last two available completed milestones, unless it's one
    const mockLastCompleteMilestone =
      lastCompleteMilestone === 1 ? 1 : Math.max(lastCompleteMilestone - 1, 0);
    const el = partRefs.current[mockLastCompleteMilestone];

    if (!el) {
      return;
    }

    setPositionerLeft(el.offsetLeft);
  }, [lastCompleteMilestone, visibleMilestones]);

  // springs for mount/unmount
  // gateway background animation
  const gatewayBackgroundRef = useSpringRef();
  const gatewayBackground = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(3rem)',
    },
    to: {
      opacity: mounted ? 1 : 0,
      transform: mounted ? 'translateY(0rem)' : 'translateY(3rem)',
    },
    config: {
      duration: 1500,
      easing: hasMountedAnimatedIn ? easings.easeInQuart : easings.easeOutQuart,
    },
    onRest() {
      setHasMountedAnimatedIn(false);
    },
    ref: gatewayBackgroundRef,
  });

  // gateway part animation
  const gatewayPartRef = useSpringRef();
  const to = (i: number) => ({
    immediate: !visibleMilestones[i], // skip those we don't see
    opacity: visibleMilestones[i] && mounted ? 1 : 0,
    transform:
      visibleMilestones[i] && mounted
        ? 'translateX(0rem)'
        : `translateX(${hasMountedAnimatedIn ? -2 : 2}rem)`,
    delay: !visibleMilestones[i] ? 0 : (i - visibleMilestones.findIndex((val: any) => val)) * 200,
    reset: !hasMountedAnimatedIn,
    config: {
      duration: 1000,
      easing: easings.easeInOutSine,
    },
  });
  const from = (_i: number) => ({
    opacity: 0,
    transform: 'translateX(2rem)',
  });
  const [milestoneSprings] = useSprings(
    milestones.length,
    (i) => ({
      ...to(i),
      from: from(i),
      ref: gatewayPartRef, // only one ref is assigned to the first
    }),
    [milestones, visibleMilestones, mounted],
  );

  // gateway progress bar animation
  const gatewayProgressBarRef = useSpringRef();
  const gatewayProgressBar = useSpring({
    from: {
      opacity: 0,
      width: '0%',
    },
    to: {
      opacity: mounted ? 1 : 0,
      width: mounted ? '100%' : '0%',
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutCubic,
    },
    onRest() {
      setHasMountedAnimatedIn(true);
    },
    ref: gatewayProgressBarRef,
  });

  useChain(
    mounted
      ? [gatewayBackgroundRef, gatewayPartRef, gatewayProgressBarRef]
      : [gatewayProgressBarRef, gatewayPartRef, gatewayBackgroundRef],
  );

  return (
    <animated.div
      className={styles.milestonePrizeProgress}
      style={{ opacity: gatewayBackground.opacity, transform: gatewayBackground.transform }}
    >
      <div className={styles.positioner} style={{ transform: `translateX(-${positionerLeft}px` }}>
        <div className={styles.parts}>
          {milestoneSprings.map((milestoneSpringsStyles, index) => (
            <animated.div
              key={index}
              className={cx('part', { hidden: !visibleMilestones[index] })}
              ref={(el) => (partRefs.current[index] = el)}
              style={{
                ...milestoneSpringsStyles,
                zIndex: milestones.length - index,
              }}
            >
              {index > 0 && (
                <div className={styles.progressContainer}>
                  <animated.progress
                    className={styles.bar}
                    aria-label="Milestone progress"
                    value={milestones[index].milestone.progress}
                    max="100"
                    style={{
                      width:
                        // animate only the last incomplete bar
                        index !== lastCompleteMilestone &&
                        milestones[index].milestone.progress < 100
                          ? gatewayProgressBar.width
                          : '100%',
                      opacity: gatewayProgressBar.opacity,
                    }}
                  />
                </div>
              )}
              <div className={styles.point}>
                <MilestonePrizeComponent
                  milestone={milestones[index].milestone}
                  prize={milestones[index].prize}
                />
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </animated.div>
  );
}

export default MilestonePrizeProgress;

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSprings, animated, easings, useSpring, Globals } from 'react-spring';
import MilestonePoint, { Milestone } from '../milestonePoint';
import BezierEasing from 'bezier-easing';
import pxToRem from '../../../helpers/pxToRem';
import styles from './styles.module.scss';

interface Props {
  mounted?: boolean;
  milestones: Milestone[];
}

// get array of milestones that have already been fully acheived
function milestonesAchieved(milestones: Milestone[]) {
  return milestones.map((milestone) => milestone.progress === 100);
}

export function MilestoneProgress({ milestones, mounted = false }: Props) {
  // has any props that affect animation been updated?
  const [hasAnimated, setHasAnimated] = useState(false);

  // maps if milestone is asleep
  const [milestonesSleepingState, setMilestonesSleepingState] = useState(() =>
    milestones.map(() => true),
  );

  // maps if milestone animation will be skipped
  const [skipAnimation, setSkipAnimation] = useState(milestonesAchieved(milestones));

  // mock milestones to help determine how much progress made from when last updated
  const [mockMilestones, setMockMilestones] = useState(() => {
    return milestones;
  });

  useEffect(() => {
    setMockMilestones(milestones);
  }, [milestones]);

  // create springs for each milestone part
  const to = (i: number) => ({
    immediate: !hasAnimated,
    delay: skipAnimation[i] ? 0 : (i - skipAnimation.findIndex((val) => !val)) * (3000 + 500), // stagger animations but don't add onto delay previously skipped animations ( 3000 = length of time this spring animation needs; 500 = overlap of the sleeping state animation in milestonepoint (i.e. 2000(milestone point) - 1500(timeout)))
    progress: milestones[i].progress,
    onStart: () => {
      if (!skipAnimation[i] && hasAnimated) {
        setTimeout(() => {
          // delay awakening the milestone point relative the amount of progress made
          setMilestonesSleepingState(
            milestones.map((_, index) => {
              // if this milestone isn't fully achieved, then it is not yet time to wake it
              if (index === i && milestones[i].progress < 100) {
                return true;
              }
              // set milestones before as already awake, but not after
              return index > i ? true : false;
            }),
          );
        }, 3000 * (Math.abs(milestones[i].progress - mockMilestones[i].progress) / 100) - 1500);

        setLastCompleteMilestone(() => {
          if (milestones[i].progress === 100) {
            return i;
          }
          return i - 1;
        });
      }
    },
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setSkipAnimation(
        milestones.map((_, index) => {
          // if this milestone isn't fully achieved, make sure to animate it next time too
          if (index === i && milestones[i].progress < 100) {
            return false;
          }
          // skip animation for those milestones before, but not after
          return index > i ? false : true;
        }),
      );
      setHasAnimated(true);
    },
    config: {
      duration: Math.max(
        3000 * (Math.abs(milestones[i].progress - mockMilestones[i].progress) / 100),
        1500,
      ), // compare how much progress is made and reduce duration accordingly, but no shorter than 1500ms
      easing: BezierEasing(0.21, 0.73, 0.81, 0.21),
    },
  });
  const from = (_i: number) => ({ progress: 0 });
  const [milestoneSprings] = useSprings(
    milestones.length,
    (i) => ({
      ...to(i),
      from: from(i),
    }),
    milestones,
  );

  // determine the last fully complete milestone
  const [lastCompleteMilestone, setLastCompleteMilestone] = useState(() => {
    const arr = milestones.map((milestone) => milestone.progress === 100);
    if (arr.every(Boolean)) {
      // if all are true
      return arr.length - 1; // get the last value
    }
    return arr.findIndex((val) => !val) - 1; // get the last true value
  });

  // have refs for each part so can determine position
  const partRefs = useRef<HTMLDivElement[] | null[]>([]);

  // use state to store last fully complete milestone left position
  const [positionerLeft, setPositionerLeft] = useState(0);

  // has any mounted animation happened?
  const [hasMountedAnimated, setHasMountedAnimated] = useState(false);

  // update positioner when last milestone changes
  useLayoutEffect(() => {
    // show the last two available completed milestones
    let mockLastCompleteMilestone = Math.max(lastCompleteMilestone - 1, 0);
    const el = partRefs.current[mockLastCompleteMilestone];

    if (!el) {
      return;
    }

    const offset = el.offsetLeft + el.offsetWidth; // the right most point of element
    setPositionerLeft(offset);
  }, [lastCompleteMilestone]); // ani comes in, but scrolls back, like it moves from 0kg

  // spring for positioner
  const positioner = useSpring({
    immediate: (mounted && !hasAnimated) || !mounted, // check for mounted so stories don't animate
    to: {
      transform: `translateX(-${positionerLeft}px`,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutSine,
    },
  });

  // spring for mount/unmount
  const gateway = useSpring({
    immediate: !hasMountedAnimated,
    from: {
      width: hasMountedAnimated ? pxToRem(300, true) : pxToRem(500, true),
      opacity: 0,
      transform: 'translateX(-12.25rem)',
    },
    to: {
      width: !mounted ? pxToRem(300, true) : pxToRem(500, true),
      opacity: !mounted ? 0 : 1,
      transform: !mounted ? 'translateX(-12.25rem)' : 'translateX(0rem)',
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutCubic,
    },
    delay: !mounted && hasMountedAnimated ? 2000 : 0, // delays when unmounting so gatewayProgressBar can leave earlier
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setHasMountedAnimated(true);
    },
  });

  const gatewayProgressBar = useSpring({
    immediate: !hasMountedAnimated, // check for hasMountedAnimated so stories don't animate
    from: {
      opacity: 0,
      width: '0%',
    },
    to: {
      opacity: mounted ? 1 : 0,
      width: mounted ? '100%' : '0%',
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <animated.div
      className={styles.milestoneProgress}
      style={{ opacity: gateway.opacity, transform: gateway.transform }}
    >
      <animated.div className={styles.positioner} style={positioner}>
        <div className={styles.parts}>
          {milestoneSprings.map(({ progress }, index) => (
            <animated.div
              key={index}
              className={styles.part}
              ref={(el) => (partRefs.current[index] = el)}
              style={{ width: index !== 0 ? gateway.width : 0 }}
            >
              {index > 0 && (
                <div className={styles.progressContainer}>
                  <animated.progress
                    className={styles.bar}
                    aria-label="Milestone progress"
                    value={progress}
                    max="100"
                    style={{
                      width:
                        // animate only the last bar
                        (index === lastCompleteMilestone &&
                          milestones[index + 1]?.progress === 0) ||
                        (index !== lastCompleteMilestone && milestones[index].progress < 100)
                          ? gatewayProgressBar.width
                          : '100%',
                      opacity: gatewayProgressBar.opacity,
                    }}
                  />
                </div>
              )}
              <div className={styles.point}>
                <MilestonePoint
                  key={index}
                  {...milestones[index]}
                  centered={true}
                  sleeping={milestonesSleepingState[index]}
                />
              </div>
            </animated.div>
          ))}
        </div>
      </animated.div>
    </animated.div>
  );
}

export default MilestoneProgress;

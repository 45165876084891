import React from 'react';
import Image from '../../../components/image';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  gymLogo: string;
  gymName: string;
}

export function PrimaryHeader({ gymLogo, gymName, children }: Props) {
  return (
    <header>
      <div className={styles.logoHolder}>
        <Image src={gymLogo} alt={gymName} className={styles.logo} />
      </div>
      {children}
    </header>
  );
}

export default PrimaryHeader;

import { useSpring, animated, easings, useTransition } from 'react-spring';
import RollingNumber from '../rollingNumber';
import styles from './styles.module.scss';

interface Props {
  progress: number;
}

function splitToDigits(progress: number) {
  const progressDigits = progress.toString().split('');
  return progressDigits.map((digit, i) => ({
    key: progressDigits.length - 1 - i, // helps digits keep their original keys regardless how many digits there are
    val: Number(digit),
  }));
}

export function PercentageIndicator({ progress }: Props) {
  // circular progress
  const circumference = 52 * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = `${offset}`;

  // circular progress animation
  const springProps = useSpring({
    to: {
      strokeDashoffset: strokeDashoffset,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuart,
    },
  });

  // numbered progress animation
  const progressDigits = splitToDigits(progress);
  const listTransitions = useTransition(progressDigits, {
    initial: { opacity: 1, transform: 'translate3d(0%, 0px, 0px) ', maxWidth: '1000px' },
    from: { opacity: 0, transform: 'translate3d(0%, 100%, 0px) ', maxWidth: '0' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0px, 0px) ', maxWidth: '1000px' },
    leave: { opacity: 0, transform: 'translate3d(25%, 0px, 0px)', maxWidth: '0' },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuart,
    },
    keys: progressDigits.map((item) => item.key),
  });

  return (
    <div className={styles.percentageIndicator}>
      <div className={styles.radial}>
        <svg className={styles.progressRing} viewBox="0 0 120 120">
          <circle className={styles.circle} fill="transparent" r="52" cx="60" cy="60" />
          <animated.circle
            className={styles.trail}
            fill="transparent"
            r="52"
            cx="60"
            cy="60"
            style={{
              strokeDasharray: strokeDasharray,
              ...springProps,
            }}
          />
        </svg>
      </div>
      <div className={styles.textual}>
        <div className={styles.top}>
          <div className={styles.large}>
            {listTransitions((springStyles, item) => (
              <animated.span style={springStyles}>
                <RollingNumber height={152} fontSize={160} lineHeight={144} val={item.val} />
              </animated.span>
            ))}
          </div>
          <div className={styles.small}>%</div>
        </div>
        <div className={styles.bottom}>complete</div>
      </div>
    </div>
  );
}

export default PercentageIndicator;

import { useState, useMemo } from 'react';
import styles from './styles.module.scss';
import {
  useSpring,
  animated,
  useTransition,
  easings,
  useChain,
  useSpringRef,
  Globals,
} from 'react-spring';
import { Props as Contributor } from '../contributorBox';
import ContributorSlide from '../contributorSlide';
import { useEffect } from 'react';

interface Props {
  contributors: Contributor[];
  mounted: boolean;
}

function sliceIntoChunks(arr: Contributor[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function ContributorSlider({ contributors, mounted = false }: Props) {
  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);
  // ready to say slides are mounted?
  const [ready, setReady] = useState(false);

  // portion contributors into two slides
  const contributorSlides = useMemo(() => sliceIntoChunks(contributors, 3), [contributors]);

  // gateway animation
  const gatewayOpacityRef = useSpringRef();
  const gatewayOpacity = useSpring({
    to: {
      opacity: mounted ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 2000,
    },
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setHasMountedAnimatedIn(!hasMountedAnimatedIn);
    },
    ref: gatewayOpacityRef,
  });
  // spring used for delay setting ready state only (due to VRT issues with timeout)
  const readyRef = useSpringRef();
  useSpring({
    to: {
      opacity: mounted ? 1 : 0, // we need to animate something (even if not used) so spring is run
    },
    from: {
      opacity: 0,
    },
    onStart() {
      setReady(!ready);
    },
    ref: readyRef,
  });
  // spring used for delay setting slide number only (due to VRT issues with timeout)
  const slideNumberRef = useSpringRef();
  useSpring({
    to: {
      opacity: mounted ? 1 : 0, // we need to animate something (even if not used) so spring is run
    },
    from: {
      opacity: 0,
    },
    onStart() {
      if (!mounted || Globals.skipAnimation || contributorSlides.length <= 1) {
        return;
      }
      setSlideNumber((state) => (state + 1) % 2);
    },
    ref: slideNumberRef,
  });
  useChain([gatewayOpacityRef, readyRef, slideNumberRef], [0, 0.2, 7]);

  // title gateway animation
  const gatewayTitleTransition = useSpring({
    from: {
      transform: 'translateX(0%)',
    },
    to: {
      transform: mounted
        ? 'translateX(0%)'
        : `translateX(${hasMountedAnimatedIn ? '-70%' : '30%'})`,
    },
    config: {
      duration: 1500,
      easing: hasMountedAnimatedIn ? easings.easeInCubic : easings.easeOutCubic,
    },
  });

  // slider animation
  const [slideNumber, setSlideNumber] = useState(0);
  const slideTransitions = useTransition(slideNumber, {
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: -0.5, transform: 'translate3d(-120%,0,0)' },
    config: {
      duration: 1000,
      easing: easings.easeInOutQuad,
    },
  });

  useEffect(() => {
    if (!Globals.skipAnimation || contributorSlides.length <= 1) {
      return;
    }

    const timer = setTimeout(() => {
      setSlideNumber((state) => (state + 1) % 2);
    }, 5000);

    return () => clearTimeout(timer);
  }, [contributorSlides]);

  return (
    <animated.div style={{ ...gatewayOpacity }} className={styles.contributorSlider}>
      <animated.h2 className={styles.title} style={gatewayTitleTransition}>
        <span className={styles.highlight}>Most Recent</span> Contributors
      </animated.h2>
      <div className={styles.slider}>
        {slideTransitions((props, i) => {
          return (
            <animated.div className={styles.contributorSlideItem} style={props}>
              <ContributorSlide contributors={contributorSlides[i]} mounted={ready} />
            </animated.div>
          );
        })}
      </div>
    </animated.div>
  );
}

export default ContributorSlider;

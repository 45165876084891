import { Screen, TabName } from '../../../models';

export interface ScreenFamilyObserver {
  onShownAllScreens: (screenFamily: ScreenFamily) => void;
}

export class ScreenFamily {
  private observer: ScreenFamilyObserver;
  private screens: Screen[];
  private index: number = 0;
  private shownAllScreens: boolean = false;

  constructor(observer: ScreenFamilyObserver, screens: Screen[]) {
    this.observer = observer;
    this.screens = screens;
  }

  rewind() {
    this.index = 0;
  }

  next(): Screen | undefined {
    // after showing a screen, we clear its previous slide
    // so that possibly returning it again won't show the previous slide
    const lastScreen = this.screens[this.index - 1];
    if (lastScreen?.previousSlide) {
      delete lastScreen.previousSlide;
    }

    const screen = this.screens[this.index];

    if (this.index < this.screens.length - 1) {
      this.index++;
      this.shownAllScreens = false;
    } else {
      this.index = 0;
      this.shownAllScreens = true;
      this.observer.onShownAllScreens(this);
    }

    return screen;
  }

  visibleTabs(): Set<TabName> {
    return this.screens.reduce((tabs, screen) => {
      tabs.add(screen.tab.key);
      return tabs;
    }, new Set<TabName>());
  }

  get shownAll(): boolean {
    return this.shownAllScreens;
  }
}

// Inspired by Tapas Adhikary's code https://github.com/atapas/react-play/blob/main/src/plays/date-time-counter/hooks/useCountDown.js

import { useEffect, useState } from 'react';

/**
 * Get the value of days, hours, minutes, and seconds each second
 */
const useCountdown = (targetDate: string | number | Date, active: boolean = true) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(() => {
    const orig = countDownDate - new Date().getTime();
    return orig > 0 ? orig : 0;
  });

  useEffect(() => {
    if (!active) {
      return;
    }

    const interval = setInterval(() => {
      const timeLeft = countDownDate - new Date().getTime();

      // stop timer if not time left
      if (isNaN(timeLeft) || timeLeft <= 0) {
        setCountDown(0);
        clearInterval(interval);
        return;
      }
      setCountDown(timeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, active]);

  return getReturnValues(countDown);
};

/**
 * Calculate time left, broken down into number of days, hours, minutes, and seconds.
 */
const getReturnValues = (countDown: number) => {
  const days = Number(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };

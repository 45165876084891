import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import SlideLayout from '../../../layout/slide';
import NextChallengeIndicator from '../../../../components/nextChallengeIndicator';
import styles from './styles.module.scss';
import { animated, easings, useTransition } from 'react-spring';

export interface NextChallengeSlide extends Slide {
  key: 'nextChallenge';
  startDate: string;
}

export function NextChallenge(props: SlideProps<NextChallengeSlide>) {
  const slide = useSlide(props, {
    enterDuration: 2500,
    updateDuration: 500,
    leaveDuration: 3500,
  });

  const { stage } = props;

  const transition = useTransition(stage !== 'leaving' && stage !== 'unmounting', {
    from: {
      opacity: 0,
      transform: 'translateX(33%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(-35%)',
      delay: 1000,
    },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <SlideLayout>
      {transition(
        (springProps, showing) =>
          showing && (
            <animated.div className={styles.nextChallengeSlide} style={springProps}>
              <NextChallengeIndicator
                startDate={slide.startDate}
                reveal={stage === 'entering' || stage === 'mounted'}
              />
            </animated.div>
          ),
      )}
    </SlideLayout>
  );
}

export default NextChallenge;

import Loader from './loader';
import ScreenCompositor from './screenCompositor';

interface Props {
  groupId: string;
}

export function App({ groupId }: Props) {
  if (!groupId) {
    return <Placeholder />;
  }

  return (
    <Loader groupId={groupId}>
      {(group, screenSource) => <ScreenCompositor group={group} screenSource={screenSource} />}
    </Loader>
  );
}

function Placeholder() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: '2rem',
  };

  return (
    <section style={style}>
      The site must be opened with a Group ID provided in the URL, such as:
      <br />
      {window.location.origin}?group=1234
    </section>
  );
}

export default App;

import { Milestone } from '../models/challenge';

export function describeMilestones(milestones: Milestone[]) {
  return milestones.map(describeMilestone);
}

export function describeMilestone(milestone: Milestone) {
  return {
    text: milestone.target
      ? `${milestone.target.toLocaleString()} ${milestone.targetUnit}`
      : 'START',
    progress: milestone.progress,
  };
}

export function computeMilestones({
  weight,
  weightUnit,
  milestones,
}: {
  weight: number;
  weightUnit: 'kg' | 'lbs';
  milestones: { target: number }[];
}): Milestone[] {
  function progress(prevTarget: number, target: number): number {
    if (weight <= prevTarget) {
      return 0;
    }

    if (weight >= target) {
      return 100;
    }

    return Math.floor(((weight - prevTarget) / (target - prevTarget)) * 100);
  }

  if (!milestones.length) {
    return [];
  }

  const finalMilestones = [{ target: 0, targetUnit: weightUnit, progress: 100 }];

  for (let i = 0; i < milestones.length; i++) {
    const milestone = milestones[i];

    finalMilestones.push({
      target: milestone.target,
      targetUnit: weightUnit,
      progress: progress(finalMilestones[i].target, milestone.target),
    });
  }

  return finalMilestones;
}

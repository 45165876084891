import { useState } from 'react';
import { useSpring, animated, easings } from 'react-spring';
import { ShoutOut } from '../../../models/showcase';
import styles from './styles.module.scss';
import ShoutOutBoxPrimaryInfo from '../shoutOutBoxPrimaryInfo';

type Props = ShoutOut & {
  mounted: boolean;
};

export function ShoutOutBox({ mounted, ...shoutOut }: Props) {
  const [hasAnimated, setHasAnimated] = useState(false);

  // animation to show back/foreground split
  const backgroundSpring = useSpring({
    immediate: !hasAnimated && !mounted,
    to: {
      transform: mounted ? 'translate(-28%, 26%) scale(0.805)' : 'translate(0%, 0%) scale(1)',
      opacity: mounted ? 1 : 0,
    },
    from: {
      transform: 'translate(0%, 0%) scale(1)',
      opacity: 0,
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuad,
    },
    onRest() {
      setHasAnimated(true);
    },
  });
  const foregroundSpring = useSpring({
    immediate: !hasAnimated && !mounted,
    to: {
      opacity: mounted ? 1 : 0.7,
    },
    from: {
      opacity: 0.7,
    },
    config: {
      duration: 1000,
      easing: easings.easeOutCubic,
    },
  });
  const foregroundBgSpring = useSpring({
    immediate: !hasAnimated && !mounted,
    to: {
      opacity: mounted ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 1000,
      easing: easings.easeOutCubic,
    },
  });

  return (
    <div className={styles.shoutOutBox}>
      <animated.div className={styles.background} style={backgroundSpring} />
      <animated.div className={styles.foreground} style={foregroundSpring}>
        <animated.div className={styles.foregroundBg} style={foregroundBgSpring} />
        <div className={styles.info}>
          <ShoutOutBoxPrimaryInfo {...shoutOut} />
        </div>
      </animated.div>
    </div>
  );
}

export default ShoutOutBox;

import { memo } from 'react';
import { SlideKey } from '../../../models/challenge';
import TargetWeight from './targetWeight';
import PrizeAwarded from './prizeAwarded';
import PrizeProgress from './prizeProgress';
import RecentContributors from './recentContributors';
import NextChallenge from './nextChallenge';
//import { Slide, SlideComponent } from '../../../models';

// TODO: add typescript types for the SlideComponent<Slide>
const slides: Record<SlideKey, any> = {
  targetWeight: memo(TargetWeight),
  prizeAwarded: memo(PrizeAwarded),
  prizeProgress: memo(PrizeProgress),
  nextChallenge: memo(NextChallenge),
  recentContributors: memo(RecentContributors),
};

export default slides;

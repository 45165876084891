import React, { useState } from 'react';
import classNames from 'classnames/bind';
import RollingNumber from '../rollingNumber';
import styles from './styles.module.scss';
import { animated, easings, useTransition } from 'react-spring';

interface Props {
  weight: number;
}

const cx = classNames.bind(styles);

/**
 * Create comma separated number and separate into spans.
 */
function splitToCharacters(n: number) {
  const characters = (n.toLocaleString() + '').split('');
  return characters.map((character, i) => {
    const isSeparator = [',', '.'].includes(character);
    return {
      // cover most common separator locale differences
      key: characters.length - 1 - i,
      isSeparator,
      val: isSeparator ? character : Number(character),
    };
  });
}

export function WeightTicker({ weight }: Props) {
  const [hasAnimated, setHasAnimated] = useState(false);

  // weight animation
  const characters = splitToCharacters(weight);
  const listTransitions = useTransition(characters, {
    immediate: !hasAnimated,
    from: { opacity: 0, transform: 'translate3d(0%, 100%, 0px) ', maxWidth: '0' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0px, 0px) ', maxWidth: '1000px' },
    leave: { opacity: 0, transform: 'translate3d(25%, 0px, 0px)', maxWidth: '0' },
    onRest() {
      setHasAnimated(true);
    },
    config: {
      duration: 2000,
      easing: easings.easeInOutQuart,
    },
    keys: characters.map((item) => item.key),
  });

  return (
    <div className={styles.weightTicker}>
      {listTransitions((springStyles, character) => (
        <animated.div
          className={cx('character', { box: !character.isSeparator })}
          style={springStyles}
        >
          <div className={cx('fade', 'fadeTop')} />
          {character.isSeparator ? (
            character.val
          ) : (
            <RollingNumber
              height={241}
              fontSize={160}
              lineHeight={242}
              val={Number(character.val)}
            />
          )}
          <div className={cx('fade', 'fadeBottom')} />
        </animated.div>
      ))}
    </div>
  );
}

export default WeightTicker;

import { useLayoutEffect, useRef, RefObject } from 'react';
import { useSpring, animated } from 'react-spring';
import { TabName } from '../../../models';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface Props {
  active: TabName;
  visibleTabs?: Set<TabName>;
}

const cx = classNames.bind(styles);

export function TabNavigation({
  active,
  visibleTabs = new Set<TabName>(['challenge', 'showcase', 'leaderboards']),
}: Props) {
  const [springProps, api] = useSpring(() => ({
    config: { duration: 300 },
    to: { opacity: 1, left: 0, width: '0' },
    from: { opacity: 0, left: 0, width: '0' },
  }));

  const tabRefs = useRef<Record<TabName, RefObject<HTMLSpanElement>>>({
    challenge: useRef<HTMLSpanElement | null>(null),
    showcase: useRef<HTMLSpanElement | null>(null),
    leaderboards: useRef<HTMLSpanElement | null>(null),
  });

  useLayoutEffect(() => {
    const el = tabRefs.current[active].current;

    if (!el) {
      return;
    }

    api.start({
      left: el.offsetLeft,
      width: `${el.offsetWidth}px`,
    });
  }, [api, active]);

  const tabs = Object.entries(tabRefs.current).filter(([name]) => visibleTabs.has(name as TabName));

  return (
    <nav className={styles.tabNavigation}>
      <ul className={styles.list}>
        {tabs.map(([name, tab]) => (
          <li className={styles.listItem} key={name}>
            <span ref={tab} className={cx('listItemText', { active: active === name })}>
              {name}
            </span>
          </li>
        ))}
      </ul>
      <div className={styles.underbar}>
        <animated.div className={styles.highlight} style={springProps} />
      </div>
    </nav>
  );
}

export default TabNavigation;

import styles from './styles.module.scss';
import dayjs from 'dayjs';
import classNames from 'classnames/bind';

interface Props {
  type: String;
  fromDate?: string | Date | dayjs.Dayjs;
}

const cx = classNames.bind(styles);

export function FromTitle({ type, fromDate }: Props) {
  return (
    <div className={styles.fromTitle}>
      <div className={styles.inner}>
        <div className={cx('decoration', 'left')} />
        <h2 className={styles.wording}>
          <span className={styles.emphasis}>{type} from </span>
          {fromDate ? dayjs(fromDate).format('HH:mm DD/MM/YYYY') : 'All time'}
        </h2>
        <div className={cx('decoration', 'right')} />
      </div>
    </div>
  );
}

export default FromTitle;

import { useState, useCallback } from 'react';
import styles from './styles.module.scss';
import { useSpring, animated } from 'react-spring';
import useFitText from 'use-fit-text';

interface Props {
  weight: number;
  weightUnit: 'kg' | 'lbs';
}

export function ContributorBoxSecondary({ weight, weightUnit }: Props) {
  const [showResizedFont, setShowResizedFont] = useState(false);

  const formattedWeight = weight.toLocaleString() + weightUnit;

  // resize fonts and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowResizedFont(true);
  }, []);

  // weight text
  const weightFitText = useFitText({ onStart, onFinish });
  const weightFontSize = weightFitText['fontSize'];
  const weightRef = weightFitText['ref'];
  const weightSpring = useSpring({
    immediate: true,
    to: {
      opacity: showResizedFont ? 1 : 0,
      display: showResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  return (
    <div className={styles.contributorBoxSecondary}>
      <div className={styles.subTitle}>Total Volume</div>
      <div className={styles.weightHolder}>
        <animated.div
          ref={weightRef}
          className={styles.weight}
          style={{ fontSize: weightFontSize, ...weightSpring }}
        >
          {formattedWeight}
        </animated.div>
      </div>
    </div>
  );
}

export default ContributorBoxSecondary;

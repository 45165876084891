interface Token {
  access_token: string;
  expires_at_epoch: number;
}

class ResponseError extends Error {
  public readonly status: number;
  public readonly data: any;

  constructor(status: number, data: any) {
    super('Request failed');
    this.status = status;
    this.data = data;
  }
}

export default class Authenticator {
  private baseUrl: string;
  private clientId: string;
  private clientSecret: string;
  private token: Token | undefined;
  private tokenGetter: Promise<Token> | undefined;
  private version: string;

  constructor(
    baseUrl = process.env.REACT_APP_API_BASE_URL,
    clientId = process.env.REACT_APP_API_CLIENT_ID,
    clientSecret = process.env.REACT_APP_API_CLIENT_SECRET,
    version = process.env.REACT_APP_API_VERSION,
  ) {
    if (!baseUrl || !clientId || !clientSecret || !version) {
      throw new Error(
        'REACT_APP_API_BASE_URL, REACT_APP_API_CLIENT_ID, REACT_APP_API_CLIENT_SECRET, and REACT_APP_API_VERSION must be set',
      );
    }

    this.baseUrl = baseUrl;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.version = version;
  }

  async fetch<T>(uri: string, options?: RequestInit): Promise<T>;
  async fetch<T extends undefined>(uri: string, options?: RequestInit): Promise<T | undefined> {
    const response = await fetch(`${this.baseUrl}${uri}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${(await this.activeToken()).access_token}`,
        'x-brawn-api-version': this.version,
        ...options?.headers,
      },
    });

    if (!response.ok) {
      throw new ResponseError(response.status, await response.json());
    }

    if (response.status === 204) {
      return undefined;
    }

    return (await response.json()) as T;
  }

  private activeToken(): Promise<Token> {
    if (this.tokenGetter) {
      return this.tokenGetter;
    }

    if (this.token && this.token.expires_at_epoch > new Date().getTime()) {
      return Promise.resolve(this.token);
    }

    return (this.tokenGetter = this.freshToken().then((token) => {
      this.token = token;
      delete this.tokenGetter;
      return token;
    }));
  }

  private async freshToken(): Promise<Token> {
    const response = await fetch(`${this.baseUrl}/oauth/token`, {
      method: 'POST',
      body: new URLSearchParams([
        ['grant_type', 'client_credentials'],
        ['client_id', this.clientId],
        ['client_secret', this.clientSecret],
        ['scope', '*'],
      ]),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch access token.');
    }

    const token = (await response.json()) as {
      access_token: string;
      expires_in: number;
    };

    // make the token expire 5 mins earlier to give us time to refresh it in time
    const expires_at = new Date(Date.now() + (token.expires_in - 300) * 1000);

    return {
      access_token: token.access_token,
      expires_at_epoch: expires_at.getTime(),
    };
  }
}

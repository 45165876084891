import styles from './styles.module.scss';
import WeightTicker from '../weightTicker';

export type WeightUnit = 'kg' | 'lbs';

interface Props {
  weight: number;
  weightUnit: WeightUnit;
}

export function WeightIndicator({ weight, weightUnit }: Props) {
  return (
    <div className={styles['weight-indicator']}>
      <WeightTicker weight={weight} />
      <span className={styles.unit}>{weightUnit}</span>
    </div>
  );
}

export default WeightIndicator;

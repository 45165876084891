import styles from './styles.module.scss';
import DefaultImage from '../../../assets/images/default-prize.jpg';
import classNames from 'classnames/bind';
import Image from '../../image';
import asset from '../../../helpers/asset';

const cx = classNames.bind(styles);

export interface Props {
  mainTitle: string;
  subTitle?: string;
  additionalText?: string;
  imageUrl?: string;
  achieved?: boolean;
}

export function PrizeCard({
  mainTitle,
  subTitle = '',
  additionalText = '',
  imageUrl = '',
  achieved = false,
}: Props) {
  return (
    <div className={cx('prizeCard', { achieved })}>
      <div className={styles.prizeImageHolder}>
        <Image className={styles.prizeImage} src={imageUrl} fallbackSrc={asset(DefaultImage)} />
      </div>
      <div className={styles.prizeText}>
        <h3 className={cx('title', 'oneLine')}>
          {mainTitle} {subTitle}
        </h3>
        {additionalText && <p className={cx('additionalText', 'oneLine')}>{additionalText}</p>}
      </div>
    </div>
  );
}

export default PrizeCard;

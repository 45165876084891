import styles from './styles.module.scss';
import ContributorBoxPrimary from '../contributorBoxPrimary';
import ContributorBoxSecondary from '../contributorBoxSecondary';

export interface Props {
  exercise: string;
  name: string;
  weight: number;
  weightUnit: 'kg' | 'lbs';
  lifts: number;
}

export function ContributorBox({ exercise, name, weight, weightUnit, lifts }: Props) {
  return (
    <div className={styles.contributorBox}>
      <ContributorBoxPrimary exercise={exercise} name={name} lifts={lifts} />
      <ContributorBoxSecondary weight={weight} weightUnit={weightUnit} />
    </div>
  );
}

export default ContributorBox;

import { useEffect, useState, useRef } from 'react';

/**
 * Keeps track of the previous value of the variable provided.
 *
 * @param value the variable we want to track
 * @returns The previous value if available, or the current value initially.
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

/**
 * Toggles the boolean state when the provided keyboard key is pressed.
 *
 * @param key the keyboard key to listen for, e.g. 'a' or ' '
 * @param initialState the initial state of the toggle
 * @returns state of the toggle
 */
export function useKeyToggle(key: string, initialState: boolean = false) {
  const [enabled, setEnabled] = useState(initialState);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        setEnabled((enabled) => !enabled);
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [key]);

  return enabled;
}

/**
 * Allows to detect and handle mouse gestures.
 *
 * @param handlers
 * @param handlers.onClick callback for when the click event is detected
 * @param handlers.onDoubleClick callback for when the double click event is detected
 */
export function useMouseGestures({
  onClick,
  onDoubleClick,
}: {
  onClick?: CallableFunction;
  onDoubleClick?: CallableFunction;
}) {
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    const clickHandler = (e: MouseEvent) => {
      if (e.detail === 1) {
        timer = setTimeout(() => {
          onClick && onClick();
        }, 300);
      }
    };

    const doubleClickHandler = () => {
      timer && clearTimeout(timer);
      onDoubleClick && onDoubleClick();
    };

    window.addEventListener('click', clickHandler);
    window.addEventListener('dblclick', doubleClickHandler);

    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('dblclick', doubleClickHandler);
    };
  }, [onClick, onDoubleClick]);
}

import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Punch from '../../../assets/images/svgs/punch.svg';
import { useSpring, animated } from 'react-spring';

const cx = classNames.bind(styles);

export interface Milestone {
  text: string;
  progress: number;
}

interface Props extends Milestone {
  centered?: boolean;
  sleeping?: boolean; // if the component should respond to changes to active prop
}

export function MilestonePoint({ text, progress, centered = true, sleeping = true }: Props) {
  const [activeState, setActiveState] = useState(() => {
    return progress === 100;
  });

  useEffect(() => {
    if (!sleeping) {
      setActiveState(progress === 100);
    }
  }, [progress, sleeping]);

  // animation
  const config = { duration: 2000 };
  const intervals = [0, 0.6, 0.87, 1];

  // point animation
  const pointScale = [0.57, 0, 0, 1];
  const { pointAnimation } = useSpring({
    immediate: sleeping === true,
    from: {
      pointAnimation: 0,
    },
    to: {
      pointAnimation: activeState ? 1 : 0,
    },
    config,
  });
  const pointAnimationProps = {
    opacity: pointAnimation
      .to({
        range: intervals,
        output: pointScale,
      })
      .to((output) => output),
  };

  // image animation
  const imageScale = [0, 0, 0, 1];
  const { imageAnimation } = useSpring({
    immediate: sleeping === true,
    from: {
      imageAnimation: 0,
    },
    to: {
      imageAnimation: activeState ? 1 : 0,
    },

    config,
  });
  const imageAnimationProps = {
    opacity: imageAnimation
      .to({
        range: intervals,
        output: imageScale,
      })
      .to((output) => output),
  };

  // text animation
  const textScale = [0.5, 0.5, 0.5, 1];
  const { textAnimation } = useSpring({
    immediate: sleeping === true,
    from: {
      textAnimation: 0,
    },
    to: {
      textAnimation: activeState ? 1 : 0,
    },

    config,
  });
  const textAnimationProps = {
    opacity: textAnimation
      .to({
        range: intervals,
        output: textScale,
      })
      .to((output) => output),
  };

  return (
    <div className={cx('milestonePoint', { centered })}>
      <div className={cx('inner', { centered })}>
        <animated.div className={styles.point} style={pointAnimationProps}>
          <animated.img
            style={imageAnimationProps}
            src={Punch}
            className={styles.icon}
            alt="Fist bump"
          />
        </animated.div>
        <animated.div className={styles.text} style={textAnimationProps}>
          {text}
        </animated.div>
      </div>
    </div>
  );
}

export default MilestonePoint;

import styles from './styles.module.scss';
import { useTransition, animated, easings } from 'react-spring';
// import Confetti from '../confetti';
import WeightAchieved from '../weightAchieved';

export type WeightUnit = 'kg' | 'lbs';

interface Props {
  weight: number;
  weightUnit: WeightUnit;
  mounted: boolean;
}

export function CelebrationOverlay({ weight, weightUnit, mounted }: Props) {
  const transition = useTransition(mounted, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      duration: 500,
      easing: easings.easeInSine,
    },
  });

  return transition(
    (springProps, showing) =>
      showing && (
        <animated.div className={styles.celebrationOverlay} style={{ ...springProps }}>
          {/* <Confetti /> */}
          <WeightAchieved weight={weight} weightUnit={weightUnit} />
        </animated.div>
      ),
  );
}

export default CelebrationOverlay;

import { animated, easings, useTransition } from 'react-spring';
import { Slide, SlideProps } from '../../../../models';
import { useSlide } from '../../../../helpers/slides';
import { Statistic } from '../../../../models/whiteboard';
import dayjs from 'dayjs';
import SlideLayout from '../../../layout/slide';
import FromTitle from '../../../../components/whiteboard/fromTitle';
import BoardList from '../../../../components/whiteboard/boardList';
import styles from './styles.module.scss';

export interface StatisticsSlide extends Slide {
  key: 'statistics';
  fromDate?: string | dayjs.Dayjs;
  statistics: Statistic[];
}

export function Statistics(props: SlideProps<StatisticsSlide>) {
  const slide = useSlide(props, {
    enterDuration: 2500,
    updateDuration: 500,
    leaveDuration: 2500,
  });

  const { stage } = props;

  const transition = useTransition(stage === 'entering' || stage === 'mounted', {
    from: {
      opacity: 0,
      transform: 'translateY(33%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(33%)',
    },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
  });

  return (
    <SlideLayout>
      <div className={styles.boardList}>
        <BoardList
          boards={slide.statistics}
          mounted={stage === 'entering' || stage === 'mounted'}
        />
      </div>
      {transition(
        (springProps, showing) =>
          showing && (
            <animated.div style={springProps} className={styles.fromTitle}>
              <FromTitle type="Lifts" fromDate={slide.fromDate} />
            </animated.div>
          ),
      )}
    </SlideLayout>
  );
}

export default Statistics;

import { ScreenFamily, ScreenFamilyObserver } from './screenFamily';

export interface ScreenFamilySource {
  readonly canRefresh: boolean;
  readonly screenFamily: ScreenFamily;
  refresh: () => Promise<void>;
}

export abstract class ScreenFamilySourceBase implements ScreenFamilySource, ScreenFamilyObserver {
  private refreshing: boolean = false;
  private currentScreenFamily: ScreenFamily | undefined;
  private nextScreenFamily: ScreenFamily | undefined;

  protected abstract fetchScreenFamily(): Promise<ScreenFamily>;

  async refresh(): Promise<void> {
    if (!this.canRefresh) {
      return Promise.reject();
    }

    this.refreshing = true;

    // if we have current screen, then it's a refresh
    if (this.currentScreenFamily) {
      try {
        this.nextScreenFamily = await this.fetchScreenFamily();
      } catch (e) {
        // only log the error, because refreshing is done on a best effort basis
        // and if it fails, we'll just retry in the future
        console.error(e);
      }

      this.refreshing = false;
      return;
    }

    // otherwise it's an initial fetch
    try {
      this.currentScreenFamily = await this.fetchScreenFamily();
    } finally {
      this.refreshing = false;
    }
  }

  get canRefresh(): boolean {
    if (this.refreshing) {
      return false;
    }

    if (!this.currentScreenFamily) {
      return true;
    }

    return this.currentScreenFamily.shownAll && !this.nextScreenFamily;
  }

  get screenFamily(): ScreenFamily {
    if (!this.currentScreenFamily) {
      throw new Error('Screen family not loaded!');
    }

    if (this.currentScreenFamily.shownAll && this.nextScreenFamily) {
      this.currentScreenFamily = this.nextScreenFamily;
      delete this.nextScreenFamily;
    }

    return this.currentScreenFamily;
  }

  onShownAllScreens() {}
}

import { useCallback, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ShoutOut } from '../../../models/showcase';
import useFitText from 'use-fit-text';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function describeShoutouts(props: ShoutOut): [string | undefined, string, string | undefined] {
  let title: string | undefined;
  let bigVal = `${props.value.toLocaleString()}`;
  let suffix: string | undefined;

  switch (props.type) {
    case 'mostNumber':
      switch (props.reason) {
        case 'sets':
          title = 'Most sets';
          break;
        case 'reps':
          title = 'Most reps';
          break;
        case 'sessions':
          title = 'Most sessions';
          break;
        case 'volume':
          title = 'Most volume';
          bigVal = `${bigVal} ${props.weightUnit}`;
          break;
      }
      break;

    case 'targetHit':
      switch (props.reason) {
        case 'volume':
          bigVal = `${bigVal} ${props.weightUnit}`;
          suffix = 'total volume';
          break;
        default:
          suffix = props.reason;
      }
      break;
  }

  return [title, bigVal, suffix];
}

export function ShoutOutBoxPrimaryInfo(props: ShoutOut) {
  const [showTitleResizedFont, setShowTitleResizedFont] = useState(false);
  const [showBigValResizedFont, setShowBigValResizedFont] = useState(false);

  // resize fonts and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowTitleResizedFont(false);
    setShowBigValResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowTitleResizedFont(true);
    setShowBigValResizedFont(true);
  }, []);

  // title text
  const titleFitText = useFitText();
  const titleFontSize = titleFitText['fontSize'];
  const titleRef = titleFitText['ref'];
  const titleSpring = useSpring({
    immediate: true,
    to: {
      opacity: showTitleResizedFont ? 1 : 0,
      display: showTitleResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  // weight text
  const bigValFitText = useFitText({ onStart, onFinish });
  const bigValFontSize = bigValFitText['fontSize'];
  const bigValRef = bigValFitText['ref'];
  const bigValSpring = useSpring({
    immediate: true,
    to: {
      opacity: showBigValResizedFont ? 1 : 0,
      display: showBigValResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  const [title, bigVal, suffix] = describeShoutouts(props);

  return (
    <div className={styles.shoutOutBoxPrimaryInfo}>
      {title && (
        <h3 className={styles.titleHolder}>
          <animated.span
            ref={titleRef}
            className={styles.title}
            style={{ fontSize: titleFontSize, ...titleSpring }}
          >
            <span className={styles.highlight}>{title}</span>
          </animated.span>
          <animated.span
            className={styles.titlePart}
            style={{ fontSize: titleFontSize, ...titleSpring }}
          >
            this week
          </animated.span>
        </h3>
      )}
      <div className={styles.bigValHolder}>
        <animated.div
          ref={bigValRef}
          className={cx('bigVal', 'highlight')}
          style={{ fontSize: bigValFontSize, ...bigValSpring }}
        >
          {bigVal}
        </animated.div>
      </div>
      {suffix && <div className={styles.summary}>{suffix}</div>}
    </div>
  );
}

export default ShoutOutBoxPrimaryInfo;

import styles from './styles.module.scss';
import MilestonePoint, { Milestone } from '../milestonePoint';
import PrizeCard, { Props as Prize } from '../prizeCard';

export interface Props {
  milestone: Milestone;
  prize?: Prize;
}

export function MilestonePrize({ milestone, prize }: Props) {
  return (
    <div className={styles.milestonePrize}>
      {prize && (
        <div className={styles.prizeInfo}>
          <PrizeCard
            mainTitle={prize.mainTitle}
            subTitle={prize.subTitle}
            additionalText={prize.additionalText}
            imageUrl={prize.imageUrl}
            achieved={milestone.progress === 100}
          />
        </div>
      )}
      <div className={styles.milestone}>
        <MilestonePoint {...milestone} centered={false} sleeping={false} />
      </div>
    </div>
  );
}

export default MilestonePrize;

import { useState } from 'react';
import styles from './styles.module.scss';
import { useSpring, useSprings, animated, easings, Globals } from 'react-spring';
import ContributorBox, { Props as Contributors } from '../contributorBox';

interface Props {
  contributors: Contributors[];
  mounted: boolean;
}

export function ContributorSlide({ contributors, mounted = false }: Props) {
  const [hasMountedAnimatedIn, setHasMountedAnimatedIn] = useState(false);

  // gateway animation
  const gatewayOpacity = useSpring({
    to: {
      opacity: mounted ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 1500,
    },
    onRest() {
      if (Globals.skipAnimation) {
        return;
      }

      setHasMountedAnimatedIn(!hasMountedAnimatedIn);
    },
  });

  // contributors animation
  const to = (i: number) => ({
    transform: mounted
      ? 'translateX(0%)'
      : `translateX(${hasMountedAnimatedIn ? '-100%' : '100%'})`,
    delay: i * 200,
    config: {
      duration: 1500,
      easing: hasMountedAnimatedIn ? easings.easeInCubic : easings.easeOutCubic,
    },
  });
  const from = (_i: number) => ({
    transform: 'translateX(100%)',
  });
  const [contributorSprings] = useSprings(
    contributors.length,
    (i) => ({
      ...to(i),
      from: from(i),
    }),
    [contributors, mounted],
  );

  return (
    <animated.div style={gatewayOpacity} className={styles.contributorSlide}>
      {contributorSprings.map((props, index) => (
        <animated.div key={index} className={styles.contributorItem} style={props}>
          <ContributorBox
            exercise={contributors[index].exercise}
            name={contributors[index].name}
            weight={contributors[index].weight}
            weightUnit={contributors[index].weightUnit}
            lifts={contributors[index].lifts}
          />
        </animated.div>
      ))}
    </animated.div>
  );
}

export default ContributorSlide;

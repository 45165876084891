import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactNode;
}

export function Slide({ children }: Props) {
  return <div className={cx('slide')}>{children}</div>;
}

export default Slide;

import { ScreenFamily } from './screenFamily';
import { ScreenFamilySourceBase } from './screenFamilySource';
import { Group, ScoredLeaderboard, Screen } from '../../../models';
import { Client } from '../../../api';
import { StatisticsSlide } from '../../../tabs/whiteboard/slides/statistics';
import { Statistic } from '../../../models/whiteboard';
import dayjs, { ManipulateType } from 'dayjs';

export default class WhiteboardScreenFamilySource extends ScreenFamilySourceBase {
  private client: Client;
  private group: Group;

  constructor(client: Client, group: Group) {
    super();

    this.client = client;
    this.group = group;
  }

  protected async fetchScreenFamily(): Promise<ScreenFamily> {
    const group = await this.client.getGroup(this.group.id);
    const scoredLeaderboards = await this.fetchScoredLeaderboards(group);

    if (scoredLeaderboards.length) {
      return Promise.resolve(
        new ScreenFamily(this, [new StatisticsScreenFactory(group).create(scoredLeaderboards)]),
      );
    }

    return Promise.resolve(new ScreenFamily(this, []));
  }

  private async fetchScoredLeaderboards(group: Group) {
    return await Promise.all(
      group.leaderboards.map((leaderboard) =>
        this.client
          .getLeaderboardTopSets(group.id, leaderboard.position)
          .then((topSets): ScoredLeaderboard => {
            return { ...leaderboard, top_sets: topSets };
          }),
      ),
    );
  }
}

class StatisticsScreenFactory {
  private group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  create(leaderboards: ScoredLeaderboard[]): Screen {
    return {
      tab: { key: 'leaderboards' },
      slide: this.createSlide(leaderboards),
      durationMs: 10000,
    };
  }

  private createSlide(leaderboards: ScoredLeaderboard[]): StatisticsSlide {
    return {
      key: 'statistics',
      fromDate: this.establishFromDate(leaderboards[0]),
      statistics: [...leaderboards]
        .sort((a, b) => a.position - b.position)
        .map((leaderboard) => this.createStatistic(leaderboard)),
    };
  }

  private establishFromDate(leaderboard: ScoredLeaderboard): dayjs.Dayjs | undefined {
    if (!leaderboard.time) {
      return;
    }

    const units = {
      'this-week': 'week',
      'this-month': 'month',
    };

    return dayjs()
      .startOf('day')
      .subtract(1, units[leaderboard.time] as ManipulateType);
  }

  private createStatistic(leaderboard: ScoredLeaderboard): Statistic {
    const weightUnit = this.group.unit_preference === 'kg' ? 'KG' : 'LBS';

    return {
      type: leaderboard.lift_variation.name,
      rankBy: leaderboard.rank_by,
      scores: leaderboard.top_sets.map((topSet) => {
        let value;
        let unit;
        let weight = this.group.unit_preference === 'kg' ? topSet.weight : topSet.weight_pounds;
        switch (leaderboard.rank_by) {
          case 'bips':
            value = topSet.bips;
            unit = 'BIPs';
            break;
          case 'intensity':
            value = topSet.intensity_score;
            unit = 'Intensity';
            break;
          default:
            value = weight;
            unit = weightUnit;
        }
        return {
          name: `${topSet.user.first_name} ${topSet.user.last_name}`,
          value,
          weight,
          reps: topSet.reps,
          sets: topSet.sets,
          unit,
          weightUnit,
          change: !topSet.change || topSet.change === 'same' ? 'none' : topSet.change,
        };
      }),
    };
  }
}

import { Screen, TabName } from '../../models';
import { ScreenSource } from '.';
import prizeExampleImage from '../../assets/storybook/prize-example.jpg';
import prizeSmlExampleImage from '../../assets/storybook/prize-sml-example.jpg';
import asset from '../../helpers/asset';

const screens: Screen[] = [
  {
    durationMs: 5000,
    tab: {
      key: 'challenge',
    },
    slide: {
      key: 'targetWeight',
      weight: 120000,
      weightUnit: 'kg',
      progress: 68,
      contributors: 231,
      startsAt: '2022-05-01T12:22:28.000000Z',
      endsAt: '2022-05-31T18:00:00.000000Z',
      milestones: [
        {
          progress: 100,
          target: 0,
          targetUnit: 'kg',
        },
        {
          progress: 100,
          target: 50000,
          targetUnit: 'kg',
        },
        {
          progress: 100,
          target: 120000,
          targetUnit: 'kg',
        },
        {
          progress: 0,
          target: 175000,
          targetUnit: 'kg',
        },
      ],
    },
    previousSlide: {
      key: 'targetWeight',
      weight: 110912,
      weightUnit: 'kg',
      progress: 63,
      contributors: 199,
      startsAt: '2022-05-01T12:22:28.000000Z',
      endsAt: '2022-05-31T18:00:00.000000Z',
      milestones: [
        {
          progress: 100,
          target: 0,
          targetUnit: 'kg',
        },
        {
          progress: 100,
          target: 50000,
          targetUnit: 'kg',
        },
        {
          progress: 92,
          target: 120000,
          targetUnit: 'kg',
        },
        {
          progress: 0,
          target: 175000,
          targetUnit: 'kg',
        },
      ],
    },
  },
  {
    durationMs: 5000,
    tab: {
      key: 'challenge',
    },
    slide: {
      key: 'prizeAwarded',
      imageUrl: 'https://picsum.photos/1280/720',
      title: 'DJ Night',
      subtitle: 'in the Gym!',
      content: 'Bring Family & Friends',
    },
  },
  {
    durationMs: 5000,
    tab: {
      key: 'challenge',
    },
    slide: {
      key: 'prizeProgress',
      items: [
        {
          milestone: { text: 'START', progress: 100 },
        },
        {
          milestone: { text: '25,000 kg', progress: 100 },
          prize: {
            mainTitle: 'Fist bump',
            additionalText: 'Great start everybody!',
          },
        },
        {
          milestone: { text: '50,000 kg', progress: 100 },
          prize: {
            mainTitle: 'Protein & Shaker',
            additionalText: 'For all gym members',
            imageUrl: asset(prizeSmlExampleImage),
          },
        },
        {
          milestone: { text: '120,000 kg', progress: 30 },
          prize: {
            mainTitle: 'DJ Night',
            subTitle: 'in the gym!',
            additionalText: 'Bring family & friends',
            imageUrl: asset(prizeExampleImage),
          },
        },
        {
          milestone: { text: '160,000 kg', progress: 0 },
          prize: {
            mainTitle: '£10,000',
            subTitle: 'Prize Draw',
            additionalText: 'Of gym equipment for our gym',
            imageUrl: asset(prizeExampleImage),
          },
        },
        {
          milestone: { text: '210,000 kg', progress: 0 },
        },
        {
          milestone: { text: 'Member Prize', progress: 0 },
          prize: {
            mainTitle: 'Holiday',
            subTitle: 'for a lucky member',
            additionalText: 'Participating members only',
            imageUrl: asset(prizeExampleImage),
          },
        },
      ],
    },
  },
  {
    durationMs: 5000,
    tab: {
      key: 'challenge',
    },
    slide: {
      key: 'nextChallenge',
      startDate: '2022-06-01T12:22:28.000000Z',
    },
  },
  {
    durationMs: 10000,
    tab: {
      key: 'challenge',
    },
    slide: {
      key: 'recentContributors',
      contributors: [
        {
          exercise: 'Bench Press',
          name: 'Allie Holland',
          weight: 120,
          weightUnit: 'kg',
        },
        {
          exercise: 'Seated Shoulder Press',
          name: 'Francesca Castiglione',
          weight: 1200,
          weightUnit: 'lbs',
        },
        {
          exercise: 'Squat',
          name: 'Bob',
          weight: 19,
          weightUnit: 'kg',
        },
        {
          exercise: 'Deadlift',
          name: 'Janice Fitzgeraldson',
          weight: 77,
          weightUnit: 'kg',
        },
        {
          exercise: 'Leg Press',
          name: 'Dewei Lü',
          weight: 24,
          weightUnit: 'lbs',
        },
        {
          exercise: 'Barbell Row',
          name: 'Martin Koch',
          weight: 67,
          weightUnit: 'kg',
        },
      ],
    },
  },
  {
    durationMs: 6000,
    tab: {
      key: 'showcase',
    },
    slide: {
      key: 'shoutOut',
      name: 'Allie Holland',
      shoutOut: {
        type: 'targetHit',
        reason: 'volume',
        value: 105,
        weightUnit: 'kg',
      },
    },
  },
  {
    durationMs: 6000,
    tab: {
      key: 'showcase',
    },
    slide: {
      key: 'shoutOut',
      name: 'Mitchell Masters',
      shoutOut: {
        type: 'mostNumber',
        reason: 'sets',
        value: 18,
      },
    },
  },
  {
    durationMs: 10000,
    tab: {
      key: 'leaderboards',
    },
    slide: {
      key: 'statistics',
      fromDate: '2022-05-01T12:22:28.000000Z',
      statistics: [
        {
          type: 'Overhead Press',
          scores: [
            { name: 'Mitchell Masters', value: 1835, reps: 1, sets: 1, unit: 'kg', change: 'none' },
            { name: 'Etta Hart', value: 1200, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Carl Wood', value: 1150, reps: 1, sets: 1, unit: 'kg', change: 'down' },
            { name: 'Marion Jimenez', value: 1050, reps: 1, sets: 1, unit: 'kg', change: 'none' },
            { name: 'Roger Dean', value: 930, reps: 1, sets: 1, unit: 'kg', change: 'up' },
          ],
        },
        {
          type: 'Barbell Row',
          scores: [
            { name: 'Bethan Ashton', value: 1923, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Janie Luna', value: 1200, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Agnes Steward', value: 1150, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Jared Burns', value: 1050, reps: 1, sets: 1, unit: 'kg', change: 'down' },
            { name: 'Cynthia Lloyd', value: 930, reps: 1, sets: 1, unit: 'kg', change: 'up' },
          ],
        },
        {
          type: 'Lat Pulldown',
          scores: [
            { name: 'Michael Yates', value: 1372, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Hettie Pratt', value: 1200, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Maurice Ross', value: 1150, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Hannah Russell', value: 1050, reps: 1, sets: 1, unit: 'kg', change: 'up' },
            { name: 'Matilda Snyder', value: 930, reps: 1, sets: 1, unit: 'kg', change: 'up' },
          ],
        },
      ],
    },
  },
];

export default class StaticScreenSource implements ScreenSource {
  private index: number = 0;

  rewind() {
    this.index = 0;
  }

  next(): Screen {
    const screen = screens[this.index];

    if (this.index < screens.length - 1) {
      this.index++;
    } else {
      this.index = 0;
    }

    return screen;
  }

  visibleTabs(): Set<TabName> {
    return new Set<TabName>(screens.map((s) => s.tab.key));
  }
}

export function createScreenSource(): Promise<StaticScreenSource> {
  return Promise.resolve(new StaticScreenSource());
}

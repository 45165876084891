import React, { memo } from 'react';

const handlerBaseUrl = process.env.REACT_APP_IMAGE_HANDLER_BASE_URL;
const handlerBucket = process.env.REACT_APP_IMAGE_HANDLER_BUCKET;

interface Edits extends Record<string, any> {
  resize?: {
    width?: string;
    height?: string;
    fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
  };
}

function composeDynamicUrl(imageKey: string, edits: Edits): string {
  if (!imageKey || !handlerBaseUrl || !handlerBucket) {
    return imageKey;
  }

  const imageRequest = JSON.stringify({
    bucket: handlerBucket,
    key: imageKey,
    edits: edits,
  });

  return `${handlerBaseUrl}/${window.btoa(imageRequest)}`;
}

function isStaticUrl(url: string) {
  return (
    url.startsWith('/') ||
    url.startsWith('./') ||
    url.startsWith('data:') ||
    url.startsWith('http://') ||
    url.startsWith('https://')
  );
}

function propsToEdits(props: React.ComponentProps<'img'>): Edits {
  const edits: Edits = {};

  if (props.width || props.height) {
    let resize: { width?: string; height?: string } = {};

    if (props.width) {
      resize.width = String(props.width);
    }

    if (props.height) {
      resize.height = String(props.height);
    }

    edits.resize = resize;
  }

  return edits;
}

interface Props extends React.ComponentProps<'img'> {
  src: string;
  fallbackSrc?: string;
  edits?: Edits;
}

export const Image = memo(({ src, fallbackSrc, alt, edits = {}, ...props }: Props) => {
  let url = src || fallbackSrc || undefined;

  if (url && !isStaticUrl(url)) {
    url = composeDynamicUrl(url, {
      ...propsToEdits(props),
      ...edits,
    });
  }

  return <img data-testid={'Image'} src={url} alt={alt} {...props} />;
});

export default Image;

import { useState, useCallback, useMemo } from 'react';
import { Score } from '../../../models/whiteboard';
import { useSpring, animated } from 'react-spring';
import useFitText from 'use-fit-text';
import styles from './styles.module.scss';

interface Props {
  type: string;
  score?: Score;
  rankBy: string;
}

interface RankByProps {
  weight: number | undefined;
  value: number | undefined;
  unit: 'kg' | 'lbs' | undefined | string;
  reps: number | undefined;
  sets: number | undefined;
  rankBy: string;
  weightUnit: string | undefined;
}

function RankByWeightInfo(props: RankByProps) {
  return (
    <div className={styles.liftInfo}>
      <div className={styles.amountInfo}>
        <span className={styles.amount}>
          {(props.value && props.value.toLocaleString()) || ''}{' '}
        </span>
        {<span className={styles.unit}>{props.unit || ''}</span>}
      </div>
      <div className={styles.repSetInfo}>
        {props.rankBy !== 'weight' && props.weight}
        <span style={{ textTransform: 'none' }}>
          {props.rankBy !== 'weight' && props?.weight && props?.weightUnit ? props.weightUnit : ''}
        </span>
        <span style={{ textTransform: 'none' }}>
          {props.rankBy !== 'weight' && props.weight && props.reps ? ' x ' : ''}
        </span>
        {props.reps || ''}
        <span style={{ textTransform: 'none' }}>
          {props.rankBy === 'weight' && props.weight && props.reps && props.sets ? ' x ' : ''}
        </span>
        {props.rankBy === 'weight' && props?.sets ? props.sets : ''}
      </div>
    </div>
  );
}

export function PrimaryBoardInfo({ type, score, rankBy }: Props) {
  const [showResizedFont, setShowResizedFont] = useState(false);

  // break the name into smaller words if necessary
  const formattedName = useMemo(() => {
    return score?.name
      .split(' ')
      .flatMap((word) => {
        if (word.length > 10) {
          const part1 = word.substring(0, 5).concat('-');
          const part2 = word.substring(5);
          return [part1, `${part2} `];
        }
        return [`${word} `];
      })
      .join('')
      .slice(0, -1);
  }, [score?.name]);

  // resize name and hide if it is currently resizing
  const onStart = useCallback(() => {
    setShowResizedFont(false);
  }, []);
  const onFinish = useCallback(() => {
    setShowResizedFont(true);
  }, []);
  const { fontSize, ref } = useFitText({ onStart, onFinish });

  // animation to show name
  const nameSpring = useSpring({
    immediate: true,
    to: {
      opacity: showResizedFont ? 1 : 0,
      display: showResizedFont ? 'flex' : 'block', // use-fit-text cannot work out widths correctly when display is flex
    },
  });

  return (
    <div className={styles.primaryBoardInfo}>
      <h2 className={styles.title}>
        <span className={styles.rank}>
          <span className={styles.rankSmall}>#</span>
          <span className={styles.rankBig}>1</span>
        </span>
        <span className={styles.wording}>
          <span className={styles.best}>Best</span>
          <span className={styles.type}>{type}</span>
        </span>
      </h2>
      <div className={styles.lifterInfo}>
        <div className={styles.nameHolder}>
          <animated.div ref={ref} className={styles.name} style={{ fontSize, ...nameSpring }}>
            {formattedName || '-'}
          </animated.div>
        </div>
        <RankByWeightInfo
          weight={score?.weight}
          weightUnit={score?.weightUnit}
          value={score?.value}
          unit={score?.unit}
          reps={score?.reps}
          sets={score?.sets}
          rankBy={rankBy}
        />
      </div>
    </div>
  );
}

export default PrimaryBoardInfo;

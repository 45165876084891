import { useCallback, useEffect, useState } from 'react';
import { useTransition, easings } from 'react-spring';
import { ScreenSource, useScreen } from './screenSource';
import { SlideStage, Group } from '../models';
import PrimaryFooter from './layout/primaryFooter';
import PrimaryHeader from './layout/primaryHeader';
import Timer from './layout/timer';
import TabNavigation from './layout/tabNavigation';
import Tabs from '../tabs';

interface Props {
  group: Group;
  screenSource: ScreenSource;
}

export function ScreenCompositor({ group, screenSource }: Props) {
  const [screen, nextScreen] = useScreen(screenSource);
  const [slideStage, setSlideStage] = useState<SlideStage>('mounting');

  const setMounted = useCallback(() => setSlideStage('mounted'), []);
  const setLeaving = useCallback(() => setSlideStage('leaving'), []);
  const setUnmounting = useCallback(() => setSlideStage('unmounting'), []);

  useEffect(() => {
    if (slideStage === 'unmounting') {
      nextScreen();
    }
  }, [slideStage, nextScreen]);

  const transitions = useTransition(screen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 0,
    config: {
      duration: 1000,
      easing: easings.linear,
    },
    exitBeforeEnter: false,
    onRest(result, ctrl, item) {
      const isCurrentScreen = item === screen;

      if (isCurrentScreen && slideStage !== 'entering') {
        setSlideStage('entering');
      }
    },
  });

  return (
    <>
      <PrimaryHeader gymName={group.name} gymLogo={group.logo_for_dark_background_url}>
        <TabNavigation active={screen.tab.key} visibleTabs={screenSource.visibleTabs()} />
      </PrimaryHeader>
      {transitions((props, item) => {
        const Tab = Tabs[item.tab.key];
        const isCurrentScreen = item === screen;

        return (
          <Tab
            style={props}
            current={item.slide}
            previous={item.previousSlide}
            stage={isCurrentScreen ? slideStage : 'unmounting'}
            onEnter={setMounted}
            onLeave={setUnmounting}
          />
        );
      })}
      <PrimaryFooter>
        <Timer
          durationMs={screen.durationMs}
          onComplete={setLeaving}
          disabled={slideStage !== 'mounted'}
        />
      </PrimaryFooter>
    </>
  );
}

export default ScreenCompositor;

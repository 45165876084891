import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useSpring, animated, easings, useChain, useSpringRef } from 'react-spring';

export type WeightUnit = 'kg' | 'lbs';

interface Props {
  weight: number;
  weightUnit: WeightUnit;
}

const cx = classNames.bind(styles);

export function WeightAchieved({ weight, weightUnit }: Props) {
  /**
   * Create comma separated number and separate into spans.
   */
  function splitDigit(n: number) {
    return (n.toLocaleString() + '').split('').map((i, index) => {
      // cover most common seperator locale differences
      return (
        <span key={index} className={cx('character', { box: ![',', '.'].includes(i) })}>
          <span className={styles.text}>{i}</span>
        </span>
      );
    });
  }

  // opacity animation
  const intervals = [0, 0.4, 1];
  const opacityVal = [0, 0, 1];
  const { animation } = useSpring({
    from: {
      animation: 0,
    },
    to: {
      animation: 1,
    },
    config: {
      duration: 1000,
      easing: easings.easeInQuint,
    },
  });
  const opacityStyle = {
    opacity: animation
      .to({
        range: intervals,
        output: opacityVal,
      })
      .to((output) => output),
  };

  // scale animation
  const scaleRef = useSpringRef();
  const scaleSpringProps = useSpring({
    to: {
      transform: 'scale(1,1)',
    },
    from: {
      transform: 'scale(5, 5)',
    },
    config: {
      duration: 1000,
      easing: easings.easeInSine,
    },
    ref: scaleRef,
  });

  // rattle animation
  const rattleRef = useSpringRef();
  const rattleIntervals = [0, 0.2, 0.4, 0.6, 0.8, 1];
  const rattleVal = [0, -0.05, 0.03, -0.07, 0.03, 0];
  const { rattleAnimation } = useSpring({
    from: {
      rattleAnimation: 0,
    },
    to: {
      rattleAnimation: 1,
    },
    config: {
      duration: 200,
      easing: easings.linear,
    },
    ref: rattleRef,
  });

  const rattleStyle = {
    transform: rattleAnimation
      .to({
        range: rattleIntervals,
        output: rattleVal,
      })
      .to((output) => {
        return `translate(${output / 2}rem, ${output / 2}rem)`;
      }),
  };

  useChain([scaleRef, rattleRef]);

  return (
    <animated.div
      className={styles.weightAchieved}
      style={{ ...opacityStyle, ...scaleSpringProps }}
    >
      <animated.div className={styles.inner} style={rattleStyle}>
        <div className={styles.weight}>{splitDigit(weight)}</div>
        <span className={styles.unit}>{weightUnit}</span>
      </animated.div>
    </animated.div>
  );
}

export default WeightAchieved;
